import { environment } from './../../../../environments/environment.prod';
import { ContentManagerService } from 'src/app/services/content-manager.service';
import { Component, OnInit } from '@angular/core';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import {
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { Register } from 'src/app/models/register';
import { Router } from '@angular/router';
import { MatSnackBarConfig, MatSnackBar } from '@angular/material';
import { EmailValidator } from './email-validator';
import { PasswordValidator } from './password-validator';

@Component({
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.css'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { showError: true }
    }
  ]
})
export class RegistrationComponent implements OnInit {
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  securityFormGroup: FormGroup;
  tandc = './../../../../assets/docs/tandc.pdf';
  policy = './../../../../assets/docs/privacy_policy.pdf';
  messages = {
    validDate: 'valid\ndate', // optional - default 'valid\nthru'
    monthYear: 'mm/yyyy' // optional - default 'month/year'
  };

  selectedQuestion: string;
  security_questions: any;
  register: Register;
  errorText: string;
  loading = false;
  actionButtonLabel = 'Close';
  action = true;
  cardPlaceholders: any = {number: '•••• •••• •••• ••••', name: 'Cardholder Name', expiry: 'MM/YY'};
  cardMasks: any;

  constructor(
    private authService: AuthService,
    private router: Router,
    private snackBar: MatSnackBar,
    private contentManagerService: ContentManagerService
  ) {}

  ngOnInit() {
    this.router.navigate(['/']);//BW-8141
    this.firstFormGroup = new FormGroup({
      username: new FormControl('', [Validators.required, Validators.minLength(8), Validators.maxLength(15),
        Validators.pattern('^[A-z0-9]+$')]),
      email: new FormControl ('', [Validators.required, Validators.email]),
      email_conf: new FormControl ('', [Validators.required, Validators.email, EmailValidator('email')]),
      password: new FormControl('', [Validators.required, Validators.minLength(8), Validators.maxLength(15),
        Validators.pattern('^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$')]),
      password_conf: new FormControl('', [Validators.required, Validators.minLength(8), Validators.maxLength(15),
        Validators.pattern('^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$'), PasswordValidator('password')])
    });
    this.secondFormGroup = new FormGroup({
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl(' '),
      cardNo: new FormControl('', [Validators.required]),
      expiration: new FormControl('', [Validators.required]),
      cvc: new FormControl('123'),
      zip_code: new FormControl('', [Validators.required])
    });
    this.securityFormGroup = new FormGroup({
      sec_1: new FormControl ('', [Validators.required])
    });
    this.authService.GetSecurityQuestions().subscribe(data => {
      this.security_questions = data;
    });
    if (!(sessionStorage.getItem('privacy_policy') !== null && sessionStorage.getItem('privacy_policy') !== undefined)) {
      this.contentManagerService.getDefaultDocumentsPathPromise()
        .catch(() => { return; })
        .then(() => {
          if (sessionStorage.getItem('privacy_policy') !== null && sessionStorage.getItem('privacy_policy') !== undefined) {
            this.policy = sessionStorage.getItem('privacy_policy');
          }
      });
    } else {
      this.policy = sessionStorage.getItem('privacy_policy');
    }
    if (!(sessionStorage.getItem('tandc') !== null && sessionStorage.getItem('tandc') !== undefined)) {
      this.contentManagerService.getDefaultDocumentsPathPromise()
        .catch(() => { return; })
        .then(() => {
          if (sessionStorage.getItem('tandc') !== null && sessionStorage.getItem('tandc') !== undefined) {
            this.tandc = sessionStorage.getItem('tandc');
          }
      });
    } else {
      this.tandc = sessionStorage.getItem('tandc');
    }
  }

  submit() {
    this.loading = true;
    const registration: Register = {
      username: `${this.firstFormGroup.value.username}`,
      email: `${this.firstFormGroup.value.email}`,
      emailConfirmation: `${this.firstFormGroup.value.email_conf}`,
      password: `${this.firstFormGroup.value.password}`,
      passwordConfirmation: `${this.firstFormGroup.value.password_conf}`,
      cardHolderName: `${this.secondFormGroup.value.firstName}`,
      accountNumber: `${this.secondFormGroup.value.cardNo}`.replace(/\s/g, '').toString(),
      expiration: `${this.secondFormGroup.value.expiration}`.replace(/\s\//g, '').split(' ').join('').toString(),
      zipCode: `${this.secondFormGroup.value.zip_code}`.toString(),
      securityQuestion: `${this.selectedQuestion}`,
      securityAnswer: `${this.securityFormGroup.value.sec_1}`
    };
    this.authService.Register(registration, environment.client_id)
    .subscribe(data => {
      this.loading = false;
      localStorage.clear();
      localStorage.setItem('token', data.token);
      this.router.navigate(['signup/confirmation']);
    }, err => {
      this.loading = false;
      const configError = new MatSnackBarConfig();
      configError.verticalPosition = 'top';
      configError.duration = 5000;
      configError.panelClass = ['mat-snack-bar-container-full', 'mat-snack-bar-error'];
      const key = Object.keys(err.error)[0];
      this.snackBar.open(err.error.errors !== null && err.error.errors !== undefined ?
        err.error.errors : err.error !== null && err.error !== undefined ?
        err.error[key][0].length > 1 ? err.error[key][0]
        : err.error : 'Whoops! Something went wrong',
      this.action ? this.actionButtonLabel : undefined, configError);
    });
  }
}
