import { environment } from './../../environments/environment';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaderResponse, HttpHeaders } from '@angular/common/http';
import { LinkedAccount } from '../models/linked_account';
import { TransactionsForEraser } from '../models/transactionsForEraser';
import { AdditionalProduct } from '../models/additionalProduct';

@Injectable()
export class AccountService {

  baseApiUrl = environment.baseApiUrl;

  constructor(private http: HttpClient) { }

  GetAvailablePoints(): Observable<any> {
    return this.http.get<any>(this.baseApiUrl + 'account/availablepoints');
  }
  async GetHomeAvailablePoints(): Promise<any> {
    await this.GetAvailablePoints().toPromise().then(data => {
      return {
        availablePoints: data.availablePoints,
        availableAmount: data.availableAmount
      };
    });
  }
  GetPendingPoints(): Observable<any> {
    return this.http.get<any>(this.baseApiUrl + 'account/pendingpoints');
  }
  GetExpiringPoints(): Observable<any> {
    return this.http.get<any>(this.baseApiUrl + 'account/expiringpoints');
  }
  GetGiftPointsAvailablePoints(): Observable<any> {
    return this.http.get<any>(this.baseApiUrl + 'account/giftpoints/availablepoints');
  }
  GetAccount(): Observable<any> {
    return this.http.get<any>(this.baseApiUrl + `account`);
  }
  SaveProfile(account: any): Observable<any> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.put<any>(this.baseApiUrl + `account`, account, { headers: headers });
  }

  verifyAvailability(): Observable<boolean> {
    return this.http.get<boolean>(`${this.baseApiUrl}/account/link/availability`);
  }

  SubmitLinkedAccount(linked_account: LinkedAccount): Observable<any> {
    return this.http.post<any>(this.baseApiUrl + `account/link`, linked_account);
  }
  GetTransactions(): Observable<TransactionsForEraser[]> {
    return this.http.get<TransactionsForEraser[]>(this.baseApiUrl + `account/transactions`);
  }
  GetTransactionAccounts(): Observable<any> {
    return this.http.get<any>(this.baseApiUrl + `account/transactions/accounts`);
  }
  GetAdditionalProducts(): Observable<AdditionalProduct[]> {
    return this.http.get<AdditionalProduct[]>(this.baseApiUrl + `account/additionalProducts`);
  }
  PostAdditionalProducts(additional_Product: AdditionalProduct): Observable<any> {
    return this.http.post<any>(this.baseApiUrl + `account/additionalProducts`, additional_Product);
  }
}
