import { CssService } from './../../../services/css.service';
import { Component, OnInit, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { AccountService } from 'src/app/services/account.service';
import { MatTableDataSource, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, MatSnackBar,
   MatSnackBarConfig, MatPaginator, PageEvent, MatSort } from '@angular/material';
import { RewardsService } from 'src/app/services/rewards.service';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import { AvailablePointsService } from 'src/app/services/availablePoints.service';
import { Router } from '@angular/router';

export interface SelectedItems {
  id: number;
  points: number;
  amount: number;
}
@Component({
  selector: 'app-pay-with-points',
  templateUrl: './pay-with-points.component.html',
  styleUrls: ['./pay-with-points.component.css']
})
export class PayWithPointsComponent implements OnInit, AfterViewInit {
  @ViewChild('toolBarBreadcrumb', {static: false}) toolBarBreadcrumb: ElementRef;
  @ViewChild('componentEl', {static: false}) componentEl: ElementRef;
  token = localStorage.getItem('token');
  jwt = this.parseJWT(this.token);
  program_code = this.jwt['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/country'];

  avail_points: any;
  message: string;
  displayedColumns: string[] = ['selected', 'date', 'account', 'merchant', 'amount', 'points'];
  dataSource = new MatTableDataSource();
  ifItems: boolean;
  checked = false;
  chosenAccount: any;
  accounts: any = [];
  pending_points: any;
  loading = false;
  selectedItem: SelectedItems[] = [];
  items_selected: any;
  total_amount: any;
  total_points: any;
  avail_points2: number;
  final_points: number;
  errorText: string;
  isAccount = false;

  actionButtonLabel = 'Close';
  action = true;
  setAutoHide = true;
  autoHide = 3000;
  addExtraClass = false;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';

  pagedList = [] = [];
  length = 0;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  todo = [
    'Get to work',
    'Pick up groceries',
    'Go home',
    'Fall asleep'
  ];

  done = [
    'Get up',
    'Brush teeth',
    'Take a shower',
    'Check e-mail',
    'Walk dog'
  ];

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
                        event.container.data,
                        event.previousIndex,
                        event.currentIndex);
    }
  }

  constructor(private accountService: AccountService, private rewardService: RewardsService, public snackBar: MatSnackBar,
    private availablePointsService: AvailablePointsService, private router: Router, private cssService: CssService) { }

  ngOnInit() {
    this.router.navigate(['home']);//BW-8141
    if (localStorage.getItem('expire_pts') !== null && localStorage.getItem('avail_pts') !== null) {
      this.pending_points = +(localStorage.getItem('expire_pts'));
      this.avail_points = localStorage.getItem('avail_pts');
    } else {
      this.accountService.GetPendingPoints().subscribe(data => {
        this.pending_points = data;
      });
      this.accountService.GetAvailablePoints().subscribe(data => {
        this.avail_points = data.availablePoints - this.pending_points;
        this.avail_points = this.format_number(this.avail_points, '', ',', '');
      });
    }

    this.accountService.GetTransactions().subscribe(data => {
      if (data != null) {
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.paginator = this.paginator;
        setTimeout(() => this.dataSource.sort = this.sort);


        this.accountService.GetTransactionAccounts().subscribe(d => {
          this.accounts = d;
          if (this.accounts != null && this.accounts.length > 0) { this.isAccount = true; }
        });
      } else {
        this.message = 'You have no items in your transaction history.';

      }
    });
  }
  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    setTimeout(() => this.dataSource.sort = this.sort);

    this.cssService.styleToolbarIE(this.toolBarBreadcrumb);
    this.cssService.styleTextIE(this.componentEl);
    this.cssService.styleFieldsIE(this.componentEl);
    this.cssService.styleBtnIE(this.componentEl);
  }
  parseJWT(token: any) {
    token = localStorage.getItem('token');
    const jwtData = token.split('.')[1];
    return JSON.parse(window.atob(jwtData));
  }
  onChecked(i: number, row: any, $event) {
    const index = this.selectedItem.findIndex(x => x.id === row.id);

    if ($event.checked && index === -1) {
      this.selectedItem.push({ id: row.id, points: row.points, amount: Number(row.amount.replace(/[^0-9\.-]+/g, ''))});
      this.items_selected = this.selectedItem.length;
      this.avail_points2 = Number(this.avail_points.replace(/[^0-9\.-]+/g, ''));
      this.total_points = this.selectedItem.reduce((a, b) => +a + +b.points, 0).toString();
      this.total_amount = this.selectedItem.reduce((a, b) => +a + +b.amount, 0).toFixed(2);
      // if (this.selectedItem.length > 0) {
      //   this.total_points = +this.total_points + 175;
      // }
      this.final_points = this.avail_points2 - this.total_points;
    } else {
      this.selectedItem.splice(index, 1);
      this.items_selected = this.selectedItem.length;
      this.avail_points2 = Number(this.avail_points.replace(/[^0-9\.-]+/g, ''));
      this.total_points = this.selectedItem.reduce((a, b) => +a + +b.points, 0).toString();
      this.total_amount = this.selectedItem.reduce((a, b) => +a + +b.amount, 0).toFixed(2);
      // if (this.selectedItem.length > 0) {
      //   this.total_points = +this.total_points + 175;
      // }
      this.final_points = this.avail_points2 - this.total_points;
    }
  }
  private _createConfig() {
    const config = new MatSnackBarConfig();
    config.verticalPosition = this.verticalPosition;
    config.horizontalPosition = this.horizontalPosition;
    config.duration = this.setAutoHide ? this.autoHide : 0;
    // config.panelClass = this.addExtraClass ? ['snack-bar'] : undefined;
    return config;
  }
  isChecked(row: any) {
    const found = this.selectedItem.find(x => x.id === row.id);
    if (found) { return true; } else { return false; }
  }
  submit() {
    this.loading = true;
    const config = this._createConfig();
    if (this.chosenAccount !== undefined && this.selectedItem !== undefined) {
    const trans = {
      programCode: this.program_code,
      rewardCode: 'ERASE',
      accountId: this.chosenAccount,
      transactions: this.selectedItem
    };

    this.rewardService.PostTransactions(trans).subscribe(data => {
      this.loading = false;
      this.availablePointsService.setCartAvailablePoints()
        .then(result => {
          this.avail_points = result.availablePoints;
          this.snackBar.open('Your request was submitted successfully.', this.action ? this.actionButtonLabel : undefined, config);
          this.router.navigateByUrl('home', { skipLocationChange: true }).then(() =>
          this.router.navigate(['cart']));
        });
    }, err => {
      this.loading = false;
      const configError = new MatSnackBarConfig();
      configError.verticalPosition = 'top';
      configError.duration = 5000;
      configError.panelClass = ['mat-snack-bar-container-full', 'mat-snack-bar-error'];
      const key = Object.keys(err.error)[0];
      this.snackBar.open(err.error.errors !== null && err.error.errors !== undefined ?
        err.error.errors : err.error !== null && err.error !== undefined ?
        err.error[key][0].length > 1 ? err.error[key][0]
        : err.error : 'Whoops! Something went wrong',
      this.action ? this.actionButtonLabel : undefined, configError);
  });
} else {
  this.loading = false;
  const configError = new MatSnackBarConfig();
  configError.verticalPosition = 'top';
  configError.duration = 5000;
  configError.panelClass = ['mat-snack-bar-container-full', 'mat-snack-bar-error'];
  this.snackBar.open('Both transaction and Account should be selected.', this.actionButtonLabel, configError); }

  }
  format_number(number: number, prefix, thousand_sep, decimal_sep) {
    if (number < 0) {
      prefix = '-' + prefix;
    }
    const thousand_separator =  thousand_sep || ',';
    const decimal_separator = decimal_sep || '.';
    const regex		= new RegExp('[^' + decimal_separator + '\\d]', 'g');
    const number_string = number.toString().replace(regex, '').toString();
    const split = number_string.split(decimal_separator);
    const rest = split[0].length % 3;
    let result = split[0].substr(0, rest);
    const thousands = split[0].substr(rest).match(/\d{3}/g);

    if (thousands) {
      const separator = rest ? thousand_separator : '';
      result += separator + thousands.join(thousand_separator);
    }
    result = split[1] !== undefined ? result + decimal_separator + split[1] : result;
    return prefix === undefined ? result : (result ? prefix + result : '');
  }
}
