import { CatalogParams } from './../models/catalog-params';
import { EgcParams } from './../models/egc-params';
import { States } from './../models/states';
import { environment } from './../../environments/environment';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { GiftCard } from '../models/gift_card';
import { PaginationService } from './pagination.service';

@Injectable()
export class RewardsService {

  baseApiUrl = environment.baseApiUrl;
  clientId = environment.client_id;

  constructor(private http: HttpClient, private paginationService: PaginationService) { }

  GetMerchRewards(programCode: any, catalogParameters: CatalogParams): Observable<HttpResponse<any>> {
    let params = new HttpParams();

    if (catalogParameters.category !=  null) {
      params = params.append('category', catalogParameters.category);
    }
    if (catalogParameters.search != null) {
      params = params.append('search', catalogParameters.search);
    }

    params = params.append('pageSize', this.paginationService.ItemsPerPage.toString());

    params = params.append('pageNumber', this.paginationService.Page.toString());

    return this.http.get<any>(this.baseApiUrl + `rewards/merch/${this.clientId}/${programCode}`, { params: params, observe: 'response' });
  }
  getSearchRewards(programCode: string, catalogParameters: CatalogParams): Observable<HttpResponse<any>> {
    let params = new HttpParams();

    params = params.append('search', catalogParameters.search);

    params = params.append('pageSize', this.paginationService.ItemsPerPage.toString());

    params = params.append('pageNumber', this.paginationService.Page.toString());

    return this.http.get<any>(this.baseApiUrl + `rewards/all/${this.clientId}/${programCode}`, {params: params, observe: 'response'});

  }
  SearchMerchRewards(programCode: any, searchString: any): Observable<any> {
    return this.http.get<any>(this.baseApiUrl + `rewards/merch/${this.clientId}/${programCode}/${searchString}`);
  }
  GetGCRewards(programCode: any, catalogParameters: CatalogParams): Observable<HttpResponse<GiftCard[]>> {

    let params = new HttpParams();

    if (catalogParameters.category !=  null) {
      params = params.append('category', catalogParameters.category);
    }
    if (catalogParameters.search != null) {
      params = params.append('search', catalogParameters.search);
    }

    params = params.append('pageSize', this.paginationService.ItemsPerPage.toString());

    params = params.append('pageNumber', this.paginationService.Page.toString());

    return this.http.get<any>(this.baseApiUrl + `rewards/cert/${this.clientId}/${programCode}`, {params: params, observe: 'response'});
  }
  SearchGCRewards(programCode: any, searchString: any): Observable<any> {
    return this.http.get<any>(this.baseApiUrl + `rewards/cert/${this.clientId}/${programCode}/${searchString}`);
  }
  AddtoWishList(wish_list: any): Observable<any> {
    return this.http.post<any>(this.baseApiUrl + `rewards/wishlist`, wish_list);
  }
  GetWishList(): Observable<any> {
    return this.http.get<any>(this.baseApiUrl + `rewards/wishlist`);
  }
  GetCharityRewards(): Observable<HttpResponse<any>> {

    let params = new HttpParams();

    params = params.append('pageSize', this.paginationService.ItemsPerPage.toString());

    params = params.append('pageNumber', this.paginationService.Page.toString());

    return this.http.get<any>(this.baseApiUrl + `rewards/cert?category=charity`, { params: params, observe: 'response' });
  }
  GetLocalRewards(programCode: any, catalogParameters: CatalogParams): Observable<HttpResponse<GiftCard[]>> {

    let params = new HttpParams();

    if (catalogParameters.category !=  null) {
      params = params.append('category', catalogParameters.category);
    }
    if (catalogParameters.state !=  null) {
      params = params.append('state', catalogParameters.state);
    }
    if (catalogParameters.search != null) {
      params = params.append('search', catalogParameters.search);
    }

    params = params.append('pageSize', this.paginationService.ItemsPerPage.toString());

    params = params.append('pageNumber', this.paginationService.Page.toString());

    return this.http.get<any>(this.baseApiUrl + `rewards/cert/${this.clientId}/${programCode}`, { params: params, observe: 'response' });
  }
  GetLocalGcStates(programCode: any): Observable<States[]> {
    return this.http.get<any>(this.baseApiUrl + `rewards/states/${this.clientId}/${programCode}`);
  }
  DeleteWishListItem(wishListId: any): Observable<any> {
    return this.http.delete<any>(this.baseApiUrl + `rewards/wishlist/${wishListId}`);
  }
  ClearWishList(): Observable<any> {
    return this.http.delete<any>(this.baseApiUrl + `rewards/wishlist/clear`);
  }
  GetProductById(id: any): Observable<any> {
    return this.http.get<any>(this.baseApiUrl + `rewards/${id}`);
  }
  GetProductEc(egcParameters: EgcParams): Observable<any> {
    let params = new HttpParams();
    params = params.append('clientId', egcParameters.clientId);
    params = params.append('programCode', egcParameters.programCode);
    params = params.append('rewardCategory', egcParameters.rewardCategory);
    params = params.append('rewardCode', egcParameters.rewardCode);
    params = params.append('rewardType', egcParameters.rewardType);
    return this.http.get<any>(this.baseApiUrl + `rewards/ecert/product`, { params: params });
  }
  PostTransactions(trans: any): Observable<any> {
    return this.http.post<any>(this.baseApiUrl + `redemption/transactions`, trans);
  }
  SearchRewards(searchTerm: any): Observable<any> {
    return this.http.get<any>(this.baseApiUrl + `rewards/search?search=${searchTerm}`);
  }
  GetGCShippingFees(): Observable<any> {
    return this.http.get<any>(this.baseApiUrl + `rewards/feetable/`);
  }
  GetECRewards(programCode: any, catalogParameters: CatalogParams): Observable<HttpResponse<GiftCard[]>> {

    let params = new HttpParams();

    if (catalogParameters.category !=  null) {
      params = params.append('category', catalogParameters.category);
    }
    if (catalogParameters.search != null) {
      params = params.append('search', catalogParameters.search);
    }

    params = params.append('pageSize', this.paginationService.ItemsPerPage.toString());

    params = params.append('pageNumber', this.paginationService.Page.toString());

    return this.http.get<any>(this.baseApiUrl + `rewards/ecert/${this.clientId}/${programCode}`, { params: params, observe: 'response' });
  }
}
