import { environment } from './../../../../environments/environment.prod';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { MatStepper, MatSnackBarConfig, MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-auth-recovery',
  templateUrl: './auth-recovery.component.html',
  styleUrls: ['./auth-recovery.component.css']
})
export class AuthRecoveryComponent implements OnInit {
  acct_recovery: FormGroup;
  acct_recovery2: FormGroup;
  errorText: string;
  errorText2 = '';
  security_question: string;
  account_no: { accountNumber: any; };
  actionButtonLabel = 'Close';
  action = true;
  stepperSelectedIndex = 0;
  id = '';
  loading = false;


  constructor(private authService: AuthService,
          private snackBar: MatSnackBar) { }

  ngOnInit() {
    this.acct_recovery = new FormGroup({
      card_no: new FormControl('', [Validators.required, Validators.minLength(16), Validators.maxLength(16)])
    });
    this.acct_recovery2 = new FormGroup({
      sec_answer: new FormControl('', [Validators.required])
    });
  }
  submit() {
    this.loading = true;
    this.account_no  = { accountNumber: this.acct_recovery.controls.card_no.value.toString() };

    this.authService.Recover1(this.account_no, environment.client_id).subscribe(data => {
      localStorage.setItem('securityQuestion', data.securityQuestion);
      this.security_question = localStorage.getItem('securityQuestion');
      this.account_no = null;
      this.acct_recovery.value.card_no = '';
      localStorage.setItem('id', data.id);
      this.id = localStorage.getItem('id');

      this.loading = false;

      this.stepperSelectedIndex++;
    }, err => {
      this.loading = false;
      const configError = new MatSnackBarConfig();
      configError.verticalPosition = 'top';
      configError.duration = 5000;
      configError.panelClass = ['mat-snack-bar-container-full', 'mat-snack-bar-error'];
      const key = Object.keys(err.error)[0];
      this.snackBar.open(err.error.errors !== null && err.error.errors !== undefined ?
        err.error.errors : err.error !== null && err.error !== undefined ?
        err.error[key][0].length > 1 ? err.error[key][0]
        : err.error : 'Whoops! Something went wrong',
      this.action ? this.actionButtonLabel : undefined, configError);
    });
  }

  submit2() {
    this.loading = true;
    const account = {
      id: this.id,
      securityQuestion: this.security_question,
      securityAnswer: this.acct_recovery2.value.sec_answer
    };
    this.authService.Recover2(account, environment.client_id).subscribe(data => {
      this.loading = false;
      this.stepperSelectedIndex++;
      localStorage.clear();
    }, err => {
      this.loading = false;
      const configError = new MatSnackBarConfig();
      configError.verticalPosition = 'top';
      configError.duration = 5000;
      configError.panelClass = ['mat-snack-bar-container-full', 'mat-snack-bar-error'];
      const key = Object.keys(err.error)[0];
      this.snackBar.open(err.error.errors !== null && err.error.errors !== undefined ?
        err.error.errors : err.error !== null && err.error !== undefined ?
        err.error[key][0].length > 1 ? err.error[key][0]
        : err.error : 'Whoops! Something went wrong',
      this.action ? this.actionButtonLabel : undefined, configError);
    });
  }
}
