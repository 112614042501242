import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class ContentManagerService {

  contentManagerUrl = environment.contentManagerAPI;
  clientId = environment.client_id;

  constructor(private http: HttpClient, private sanitizer: DomSanitizer) { }


  getColor(programCode: string): Observable<any> {
    let demo = localStorage.getItem('Demo');
    if (demo === null || demo === undefined) {
      demo = 'demo';
    }
    return this.http.get<string>(`${this.contentManagerUrl}css/colors/${this.clientId}/${programCode}/${demo}`);
  }

  isDemo(): Observable<boolean> {
    let demo = localStorage.getItem('Demo');
    if (demo === null || demo === undefined) {
      demo = sessionStorage.getItem('Demo');
      if (demo === null || demo === undefined) {
        demo = 'demo';
      }
    }
    return this.http.get<boolean>(`${this.contentManagerUrl}css/demo/${demo}`);
  }

  getColorDefault(): Observable<any> {
    let demo = sessionStorage.getItem('Demo');
    if (demo === null || demo === undefined) {
      demo = 'false';
    }
    return this.http.get<string>(`${this.contentManagerUrl}css/colors/${this.clientId}/default/${demo}`);
  }

  getProgramName(programCode: string): Observable<any> {
    return this.http.get<any>(`${this.contentManagerUrl}css/name/${this.clientId}/${programCode}`);
  }

  getProgramNameToPromise() {
    const programCode = this.getProgramCode();
    return this.http.get<any>(`${this.contentManagerUrl}css/name/${this.clientId}/${programCode}`)
      .toPromise()
      .then(data => localStorage.setItem('program_data', JSON.stringify(data)));
  }

  getDefaultProgramNameToPromise() {
    return this.http.get<any>(`${this.contentManagerUrl}css/name/${this.clientId}/DEFAULT`)
      .toPromise()
      .then(data => sessionStorage.setItem('program_data', JSON.stringify(data)));
  }

  getDocuments(programCode: string) {
    return this.http.get<any>(`${this.contentManagerUrl}doc/${this.clientId}/${programCode}`);
  }

  getBannerImages(programCode: string) {
    return this.http.get<any>(`${this.contentManagerUrl}doc/img/${this.clientId}/${programCode}`);
  }

  getLogoImageToPromise() {

    const programCode = this.getProgramCode();

    return this.http.get(`${this.contentManagerUrl}doc/logo/${this.clientId}/${programCode}`)
      .toPromise()
      .then(this.loadlogo);
  }

  getLogoImagePathToPromise() {
    const programCode = this.getProgramCode();

    return this.http.get(`${this.contentManagerUrl}doc/logo/${this.clientId}/${programCode}/path`)
      .toPromise()
      .then(this.loadLogoPath);
  }

  getDefaultLogoImagePathToPromise() {

    return this.http.get(`${this.contentManagerUrl}doc/logo/${this.clientId}/DEFAULT/path`)
      .toPromise()
      .then(this.loadLogoPathDefault);
  }

  loadlogo(data) {
    localStorage.setItem(data.name.toString().replace('.', '').replace('png', ''),
      `data:${data.extension};base64,${data.file}`);
  }

  loadLogoPathDefault(data) {
    sessionStorage.setItem(data.documentType, data.url);
  }

  loadLogoPath(data) {
    localStorage.setItem(data.documentType, data.url);
  }

  getBannerImagesPromise(): Promise<any> {
    const programCode = this.getProgramCode();

    return this.http.get(`${this.contentManagerUrl}doc/img/${this.clientId}/${programCode}`)
      .toPromise()
      .then(this.loadbannerImages);
  }

  getBannerImagesPathPromise(): Promise<any> {
    const programCode = this.getProgramCode();

    return this.http.get(`${this.contentManagerUrl}doc/img/${this.clientId}/${programCode}/path`)
      .toPromise()
      .then(this.loadBannerImagesPaths);
  }

  getDefaultBannerImagesPathPromise(): Promise<any> {

    return this.http.get(`${this.contentManagerUrl}doc/img/${this.clientId}/DEFAULT/path`)
      .toPromise()
      .then(this.loadBannerImagesPathsDefault);
  }

  getDefaultDocumentsPathPromise(): Promise<any> {
    return this.http.get(`${this.contentManagerUrl}doc/${this.clientId}/DEFAULT/path`)
      .toPromise()
      .then(this.loadDocumentsPathsDefault);
  }

  getDocumentsPathPromise(): Promise<any> {
    const programCode = this.getProgramCode();
    return this.http.get(`${this.contentManagerUrl}/doc/${this.clientId}/${programCode}/path`)
      .toPromise()
      .then(this.loadDocumentsPaths);
  }
  getProgramCode() {
    const token = localStorage.getItem('token');
    const jwtData = token.split('.')[1];
    const decodedJWT = JSON.parse(window.atob(jwtData));
    return decodedJWT['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/country'];
  }

  loadbannerImages(data) {
    data.forEach(element => {
          localStorage.setItem(element.name.toString().replace('.', '').replace('png', ''),
          `data:${element.extension};base64,${element.file}`);
        });
  }

  loadBannerImagesPaths(data) {
    data.forEach(element => {
      localStorage.setItem(element.documentType, element.url);
    });
  }

  loadBannerImagesPathsDefault(data) {
    data.forEach(element => {
      sessionStorage.setItem(element.documentType, element.url);
    });
  }
  loadDocumentsPaths(data) {
    data.forEach(element => {
      localStorage.setItem(element.documentType, element.url);
    });
  }

  loadDocumentsPathsDefault(data) {
    data.forEach(element => {
      sessionStorage.setItem(element.documentType, element.url);
    });
  }
  loadCpp(data) {
    localStorage.setItem('cpp', JSON.stringify(data, ['category', 'webDisplay']));
  }

  loadCppDefault(data) {
    sessionStorage.setItem('cpp', JSON.stringify(data, ['category', 'webDisplay']));
  }

  getCppsToPromise(): Promise<any> {
    const programCode = this.getProgramCode();

    return this.http.get(`${this.contentManagerUrl}/cpp/web/${this.clientId}/${programCode}`)
      .toPromise()
      .then(this.loadCpp);
  }

  getDefaultCppsToPromise(): Promise<any> {
    return this.http.get(`${this.contentManagerUrl}/cpp/web/${this.clientId}/DEFAULT`)
      .toPromise()
      .then(this.loadCppDefault);
  }
}
