import { AccountService } from './account.service';
import { Injectable } from '@angular/core';
import { SessionPoints } from '../models/session-points';

@Injectable({
  providedIn: 'root'
})
export class AvailablePointsService {

constructor(private accountService: AccountService) { }


// getPendingPoints(): number {
//   let pendingPoints = 0;
//   this.accountService.GetPendingPoints().subscribe((d: number) => {
//     console.log(d);
//     pendingPoints = d;
//     return pendingPoints;
//   });
// }

sessionPoints: SessionPoints = {
  availablePoints: '',
  pendingPoints: '',
  expiringPoints: '',
  availableDollars: '',
};

setSessionAvailablePoints(): any {
  this.accountService.GetAvailablePoints().subscribe(data => {
    this.accountService.GetPendingPoints().subscribe((d: number) => {
      this.accountService.GetExpiringPoints().subscribe(expire => {
        localStorage.setItem('avail_pts', this.format_number(data.availablePoints - d, '', ',', ''));
        // data.availableAmountlocalStorage.setItem('avail_dollars', this.format_number(data.availableAmount, '$', ',', ''));
        localStorage.setItem('avail_dollars', data.availableAmount);
        localStorage.setItem('expire_pts', this.format_number(expire, '', ',', ''));
        localStorage.setItem('pending_pts', this.format_number(d, '', ',', ''));
      });
    });
  });
}

async setCartAvailablePoints(): Promise<SessionPoints> {
  await this.accountService.GetAvailablePoints().toPromise().then(async data => {
    await this.accountService.GetPendingPoints().toPromise().then(async (d: number) => {
      await this.accountService.GetExpiringPoints().toPromise().then(expire => {
        this.sessionPoints = {
          availablePoints: this.format_number(data.availablePoints - d, '', ',', ''),
          pendingPoints: this.format_number(d, '', ',', ''),
          expiringPoints: this.format_number(expire, '', ',', ''),
          // availableDollars: this.format_number(data.availableAmount, '$', ',', '')
          availableDollars: data.availableAmount
        };
        localStorage.setItem('avail_pts', this.format_number(data.availablePoints - d, '', ',', ''));
        // localStorage.setItem('avail_dollars', this.format_number(data.availableAmount, '$', ',', ''));
        localStorage.setItem('avail_dollars', data.availableAmount);
        localStorage.setItem('expire_pts', this.format_number(expire, '', ',', ''));
        localStorage.setItem('pending_pts', this.format_number(d, '', ',', ''));
      });
    });
  });
  return this.sessionPoints;
}

// setSessionExpiringPoints(): number {
//   let expiringPoints = 0;
//   this.accountService.GetExpiringPoints().subscribe(d => {
//     expiringPoints = d;
//   });
//   return +expiringPoints;
// }

format_number(number: number, prefix, thousand_sep, decimal_sep) {
  if (number < 0) {
    prefix = '-' + prefix;
  }
  const thousand_separator =  thousand_sep || ',';
  const decimal_separator = decimal_sep || '.';
  const regex		= new RegExp('[^' + decimal_separator + '\\d]', 'g');
  const number_string = number.toString().replace(regex, '').toString();
  const split = number_string.split(decimal_separator);
  const rest = split[0].length % 3;
  let result = split[0].substr(0, rest);
  const thousands = split[0].substr(rest).match(/\d{3}/g);

  if (thousands) {
    const separator = rest ? thousand_separator : '';
    result += separator + thousands.join(thousand_separator);
  }
  result = split[1] !== undefined ? result + decimal_separator + split[1] : result;
  return prefix === undefined ? result : (result ? prefix + result : '');
}

}
