import { PointHistoryComponent } from './../../points/point-history/point-history.component';
import { AvailablePointsService } from './../../../services/availablePoints.service';
import { CatalogParams } from './../../../models/catalog-params';
import { Component, OnInit, isDevMode, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { AccountService } from 'src/app/services/account.service';
import { RewardsService } from 'src/app/services/rewards.service';
import { PageEvent, MatSnackBarConfig, MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition, MatSnackBar, MatPaginator } from '@angular/material';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { RedemptionService } from 'src/app/services/redemption.service';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Product } from 'src/app/models/product';
import { GiftCard } from 'src/app/models/gift_card';
import { Denoms } from 'src/app/models/denoms';
import { PaginationService } from 'src/app/services/pagination.service';
import { environment } from 'src/environments/environment';
import { CssService } from 'src/app/services/css.service';

@Component({
  selector: 'app-gift-card-catalog',
  templateUrl: './gift-card-catalog.component.html',
  styleUrls: ['./gift-card-catalog.component.css']
})
export class GiftCardCatalogComponent implements OnInit, AfterViewInit {
  token = localStorage.getItem('token');
  jwt = this.parseJWT(this.token);
  program_code = this.jwt['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/country'];
  avail_points: any;
  merch_rewards = [] = [];
  @ViewChild('Paginator1', { static: true }) paginator1: MatPaginator;
  @ViewChild('Paginator2', { static: true }) paginator2: MatPaginator;
  @ViewChild('toolBarBreadcrumb', {static: false}) toolBarBreadcrumb: ElementRef;
  @ViewChild('componentEl', {static: false}) componentEl: ElementRef;

  denoms: Denoms[] = [];
  productsList: GiftCard[] = [];
  pagedList: GiftCard[] = [];
  breakpoint = 4;  // to adjust to screen
  // MatPaginator Inputs
  length = 0;
  pageSize = 10;  // displying three cards each row
  pageSizeOptions: number[] = [12, 24, 48];

  // quantity_form: FormGroup;
  productId: any;
  errorText: string;
  formArr = [];

  actionButtonLabel = 'Close';
  action = true;
  setAutoHide = true;
  autoHide = 3000;
  addExtraClass = false;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';
  pending_points: any;
  img_url = [] = [];
  loading = false;
  categorySelected: string;
  rewardsAvailable = false;
  searchValue = '';

  categories = [
    {value: 'all', viewValue: 'All'},
    {value: 'retail', viewValue: 'Retail'},
    {value: 'dine', viewValue: 'Dining'},
    {value: 'hotel', viewValue: 'Hotels and Cars'},
    // {value: 'shop', viewValue: 'Shopping'},
    {value: 'groc', viewValue: 'Groceries'},
    {value: 'gas', viewValue: 'Gas'},

  ];

  constructor(private accountService: AccountService, private rewardsService: RewardsService, private redemptionService: RedemptionService,
    public snackBar: MatSnackBar,
    private router: Router,
    private formBuilder: FormBuilder,
    private availablePointsService: AvailablePointsService,
    public paginationService: PaginationService,
    private route: ActivatedRoute,
    private cssService: CssService) {
      this.route.params.subscribe(params => {
        if (params !== null) {
          this.categorySelected = params['cat'];
          let loaded = false;
          this.router.events.subscribe(e => {
            if (e instanceof NavigationEnd && !loaded) {
              loaded = true;
              this.getRewards(this.categorySelected, null);
            }
          });
        } else {
          this.categorySelected = 'all';
        }
      });

      this.paginationService.paginationModel.currentPage = 1;
     }

  ngOnInit() {
    this.router.navigate(['home']);//BW-8141
    this.loading = true;
    if (localStorage.getItem('expire_pts') !== null && localStorage.getItem('avail_pts') !== null) {
      this.pending_points = +(localStorage.getItem('expire_pts'));
      this.avail_points = localStorage.getItem('avail_pts');
    } else {
      this.accountService.GetPendingPoints().subscribe(data => {
        this.pending_points = data;
      });
      this.accountService.GetAvailablePoints().subscribe(data => {
        this.avail_points = data.availablePoints - this.pending_points;
        this.avail_points = this.format_number(this.avail_points, '', ',', '');
      });
    }

    this.getRewards(this.categorySelected);
    if (window.innerWidth <= 800) {
      this.breakpoint = 1;
    } else if (800 < window.innerWidth && window.innerWidth <= 992) {
      this.breakpoint = 2;
    } else if (992 < window.innerWidth && window.innerWidth <= 1200) {
      this.breakpoint = 3;
    } else {
      this.breakpoint = 4;
    }
  }

  ngAfterViewInit(): void {
    this.cssService.styleToolbarIE(this.toolBarBreadcrumb);
    this.cssService.styleTextIE(this.componentEl);
    this.cssService.styleFieldsIE(this.componentEl);
    this.cssService.styleBtnIE(this.componentEl);
  }

  getRewards(category?: string, search?: string) {
    this.loading = true;
    this.formBuilder = new FormBuilder();
    const params = new CatalogParams();
    params.category = category;
    params.search = search;
    this.rewardsService.GetGCRewards(this.program_code, params).subscribe(result => {
      if (result.body !== null && result.body !== null) {
        this.rewardsAvailable = true;
        this.loading = false;
        this.productsList = result.body;
        this.pagedList = result.body;

        this.paginationService.paginationModel.currentPage = JSON.parse(result.headers.get('Pagination')).currentPage;
        this.paginationService.paginationModel.itemsPerPage = JSON.parse(result.headers.get('Pagination')).itemsPerPage;
        this.paginationService.paginationModel.totalPages = JSON.parse(result.headers.get('Pagination')).totalPages;
        this.paginationService.paginationModel.totalItems = JSON.parse(result.headers.get('Pagination')).totalItems;

        // this.pagedList = data.slice(0, 10);
        this.length = result.body.length;
        this.img_url = [];
        for (let i = 0; i < this.pagedList.length; i++) {
          this.img_url.push(this.GetImageURL(this.pagedList[i].denominations[0].code, '150'));
          this.pagedList[i].imgUrl = this.img_url[i];
        }
        this.formArr = [];
        this.pagedList.forEach((x) => {
          this.formArr.push(this.formBuilder.group({
            quantity: this.formBuilder.control(1, [Validators.required]),
            denom: this.formBuilder.control('', [Validators.required])
          }));
        });
      } else {
        this.rewardsAvailable = false;
      }

    });
  }
  parseJWT(token: any) {
    token = localStorage.getItem('token');
    const jwtData = token.split('.')[1];
    return JSON.parse(window.atob(jwtData));
  }
  OnPageChange(event: PageEvent) {
    const startIndex = event.pageIndex * event.pageSize;
    let endIndex = startIndex + event.pageSize;
    if (endIndex > this.length) {
      endIndex = this.length;
    }
    // this.pagedList = this.productsList.slice(startIndex, endIndex);
  }
  onResize(event) {
    if (window.innerWidth <= 800) {
      this.breakpoint = 1;
    } else if (800 < window.innerWidth && window.innerWidth <= 992) {
      this.breakpoint = 2;
    } else if (992 < window.innerWidth && window.innerWidth <= 1200) {
      this.breakpoint = 3;
    } else {
      this.breakpoint = 4;
    }

    // this.breakpoint = ( 800 < window.innerWidth && window.innerWidth <= 992) ? 2 : 4;
    // this.breakpoint = (window.innerWidth <= 800) ? 1 : 2;
  }
  applyFilter(filterValue: any) {

    this.searchValue = filterValue.toString();

    this.getRewards(null, filterValue);

    if (window.innerWidth <= 800) {
      this.breakpoint = 1;
    } else if (800 < window.innerWidth && window.innerWidth <= 992) {
      this.breakpoint = 2;
    } else if (992 < window.innerWidth && window.innerWidth <= 1200) {
      this.breakpoint = 3;
    } else {
      this.breakpoint = 4;
    }
  }
  viewDetails(formValues: any) {
    const id = formValues.denom;
    this.router.navigate([`rewards/product/${id}`]);
  }
  GetImageURL(reward_code: any, size: any) {
    if (isDevMode()) {
      // let useSize = '150';
      // useSize = size;
      // console.log(`${environment.baseCors}assets/images/RewardImages/150/${reward_code}.jpg`);

      // return `${environment.baseCors}assets/images/RewardImages/150/${reward_code}.jpg`;
      // // return '../../../assets/images/RewardImages/150/' + reward_code + '.jpg';
    }
    // console.log(`${environment.baseCors}assets/images/RewardImages/150/${reward_code}.jpg`);

    if (environment.baseCors.includes('bwloyalty')) {
      return `${environment.baseCors}RewardImages/150/${reward_code}.jpg`.replace('www.', '');
    } else {
      return `${environment.baseCors}RewardImages/150/${reward_code}.jpg`;
    }
  }
  private _createConfig() {
    const config = new MatSnackBarConfig();
    config.verticalPosition = this.verticalPosition;
    config.horizontalPosition = this.horizontalPosition;
    config.duration = this.setAutoHide ? this.autoHide : 0;
    return config;
  }
  CartAdd(formValues: any) {

    this.loading = true;
    const config = this._createConfig();

    this.rewardsService.GetProductById(formValues.denom)
      .subscribe(data => {

        const redemption = {
          rewardId: data.id,
          quantity: formValues.quantity,
          denomination: data.cost,
          pointsRequired: data.points,
          type: data.type,
          code: data.code
        };

        const availablePoints = this.avail_points.replace(',', '');
        if (availablePoints >= (redemption.pointsRequired * redemption.quantity)) {
          this.redemptionService.AddtoCart(redemption).subscribe(() => {
            this.loading = false;
            this.availablePointsService.setCartAvailablePoints()
              .then(result => {
                this.avail_points = result.availablePoints;
                this.snackBar.open('Item has been added to your cart.', '', config);
                this.router.navigateByUrl('home', { skipLocationChange: true }).then(() =>
                this.router.navigate(['rewards/catalog/gift_card']));
              });
          }, err => {
              this.loading = false;
              const configError = new MatSnackBarConfig();
              configError.verticalPosition = 'top';
              configError.duration = 5000;
              configError.panelClass = ['mat-snack-bar-container-full', 'mat-snack-bar-error'];
              const key = Object.keys(err.error)[0];
              this.snackBar.open(err.error.errors !== null && err.error.errors !== undefined ?
                err.error.errors : err.error !== null && err.error !== undefined ?
                err.error[key][0].length > 1 ? err.error[key][0]
                : err.error : 'Whoops! Something went wrong',
              this.action ? this.actionButtonLabel : undefined, configError);
          });
        } else {
          this.loading = false;
              const configError = new MatSnackBarConfig();
              configError.verticalPosition = 'top';
              configError.duration = 5000;
              configError.panelClass = ['mat-snack-bar-container-full', 'mat-snack-bar-error'];


              this.snackBar.open('You don\'t have enough points to redeem this item',
                this.action ? this.actionButtonLabel : undefined, configError);
        }
      });
  }
  WishListAdd(product: any, formValues: any) {
    this.loading = true;
    const config = this._createConfig();
    this.rewardsService.GetProductById(formValues.denom)
      .subscribe(data => {
        const wish_list = {
          rewardCode: data.rewardCode,
          quantity: formValues.quantity,
          rewardType: product.type
        };
        this.rewardsService.AddtoWishList(wish_list).subscribe(() => {
          this.loading = false;
          this.snackBar.open('Item has been added to your wish list.', '', config);
          this.router.navigateByUrl('home', { skipLocationChange: true }).then(() =>
          this.router.navigate(['rewards/catalog/gift_card']));
        }, err => {
            this.loading = false;
            const configError = new MatSnackBarConfig();
            configError.verticalPosition = 'top';
            configError.duration = 5000;
            configError.panelClass = ['mat-snack-bar-container-full', 'mat-snack-bar-error'];
            const key = Object.keys(err.error)[0];
            this.snackBar.open(err.error.errors !== null && err.error.errors !== undefined ?
              err.error.errors : err.error !== null && err.error !== undefined ?
              err.error[key][0].length > 1 ? err.error[key][0]
              : err.error : 'Whoops! Something went wrong',
            this.action ? this.actionButtonLabel : undefined, configError);
        });
      });
  }

  onCategoryChanged() {
    this.loading = true;
    this.paginator1.pageIndex = 0;
    this.paginator2.pageIndex = 0;
    this.paginationService.paginationModel.currentPage = 1;
    this.getRewards(this.categorySelected);
    this.loading = false;
  }

  format_number(number: number, prefix, thousand_sep, decimal_sep) {
    if (number < 0) {
      prefix = '-' + prefix;
    }
    const thousand_separator =  thousand_sep || ',';
    const decimal_separator = decimal_sep || '.';
    const regex		= new RegExp('[^' + decimal_separator + '\\d]', 'g');
    const number_string = number.toString().replace(regex, '').toString();
    const split = number_string.split(decimal_separator);
    const rest = split[0].length % 3;
    let result = split[0].substr(0, rest);
    const thousands = split[0].substr(rest).match(/\d{3}/g);

    if (thousands) {
      const separator = rest ? thousand_separator : '';
      result += separator + thousands.join(thousand_separator);
    }
    result = split[1] !== undefined ? result + decimal_separator + split[1] : result;
    return prefix === undefined ? result : (result ? prefix + result : '');
  }

  onPaginatorChange(event: PageEvent) {
    this.paginationService.change(event);
    window.scroll(0, 0);
    if (this.searchValue !== '') {
      this.getRewards(this.categorySelected, this.searchValue);
    } else {
      this.getRewards(this.categorySelected);
    }
  }
}
