export const environment = {

   baseApiUrl: 'https://www.rewardsredeemed.com/api/',
   baseCors: 'https://www.rewardsredeemed.com/',
   montroseUrl: 'https://saml2.breakawayloyalty.com/MontroseSSO.aspx?SessionID=',
   hindmerchUrl: 'https://hinda.breakawayloyalty.com/DefaultSSO.aspx?sso=',
   contentManagerAPI: 'https://contentmanager.breakawayloyalty.com/api/',
   client_id: '202102',
   azigoEndpoint: 'https://gep.partnersmall.com/sso?hash=',
   azigoSSOKey: 'o25626604d86cd8fcf4ff37d5370f32c',
   production: true

  // baseApiUrl: 'https://glacieron.bwloyalty.com/api/',
  // baseCors: 'https://glacieron.bwloyalty.com/',
  // montroseUrl: 'https://testsaml2.bwloyalty.com/MontroseSSO.aspx?SessionID=',
  // hindmerchUrl: 'https://hinda.bwloyalty.com/DefaultSSO.aspx?sso=',
  // contentManagerAPI: 'https://contentmanager.bwloyalty.com/api/',
  // client_id: '202102',
  // azigoEndpoint: 'https://gep.partnersmall.com/sso?hash=',
  // azigoSSOKey: 'o25626604d86cd8fcf4ff37d5370f32c',
  // production: true
};
