import { CssService } from './../../../services/css.service';
import { AvailablePointsService } from './../../../services/availablePoints.service';
import { Component, OnInit, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AccountService } from 'src/app/services/account.service';
import { RedemptionService } from 'src/app/services/redemption.service';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
  MatSnackBarConfig,
  MatRadioChange,
  MatSelectChange
} from '@angular/material';
import { Router } from '@angular/router';

export interface CashRebate {
  rewardCode: string;
  pointsRequired: number;
  rewardCost: number;
  text: string;
}

@Component({
  selector: 'app-cash-rebate',
  templateUrl: './cash-rebate.component.html',
  styleUrls: ['./cash-rebate.component.css']
})
export class CashRebateComponent implements OnInit, AfterViewInit {
  @ViewChild('toolBarBreadcrumb', {static: false}) toolBarBreadcrumb: ElementRef;
  @ViewChild('componentEl', {static: false}) componentEl: ElementRef;
  cashbackForm: FormGroup;
  chosenCashRebate: string;
  selectedAccount: any;
  accounts: any = [];
  selectedAccountType: any;
  avail_points: any;
  points: any;
  amount: any;
  avail_amount: any;
  actionButtonLabel = 'Close';
  action = true;
  setAutoHide = true;
  autoHide = 3000;
  addExtraClass = false;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';
  errorText: string;
  pending_points: any;
  loading = false;
  id: any;
  constructor(
    private accountService: AccountService,
    private redemptionService: RedemptionService,
    public snackBar: MatSnackBar,
    private router: Router,
    private availablePointsService: AvailablePointsService,
    private cssService: CssService
  ) {}

  ngOnInit() {
    this.router.navigate(['home']);//BW-8141
    if (
      localStorage.getItem('expire_pts') !== null &&
      localStorage.getItem('avail_pts') !== null
    ) {
      this.pending_points = +localStorage.getItem('expire_pts');
      this.avail_points = localStorage.getItem('avail_pts');
    } else {
      this.accountService.GetPendingPoints().subscribe(data => {
        this.pending_points = data;
      });
      this.accountService.GetAvailablePoints().subscribe(data => {
        this.avail_points = data.availablePoints - this.pending_points;
        this.avail_points = this.format_number(this.avail_points, '', ',', '');
      });
    }
    this.accountService.GetAvailablePoints().subscribe(data => {
      this.points = data.availablePoints;
      this.avail_amount = this.format_number(
        data.availableAmount,
        '$',
        ',',
        '.'
      );
      if (data.availablePoints < 0) {
        this.cashbackForm.patchValue({
          amount: this.format_number(0, '$', ',', '')
        });
        this.cashbackForm.patchValue({
          points: this.format_number(0, '', ',', '')
        });
      } else {
        this.cashbackForm.patchValue({
          amount: this.avail_amount
        });
        this.cashbackForm.patchValue({
          points: this.format_number(data.availablePoints, '', ',', '')
        });
      }
    });
    this.accountService.GetTransactionAccounts().subscribe(d => {
      this.accounts = d;
    });
    this.cashbackForm = new FormGroup({
      amount: new FormControl('', [Validators.required]),
      points: new FormControl('', [Validators.required])
    });
  }

  ngAfterViewInit(): void {
    this.cssService.styleToolbarIE(this.toolBarBreadcrumb);
    this.cssService.styleTextIE(this.componentEl);
    this.cssService.styleFieldsIE(this.componentEl);
    this.cssService.styleBtnIE(this.componentEl);
  }

  private _createConfig() {
    const config = new MatSnackBarConfig();
    config.verticalPosition = this.verticalPosition;
    config.horizontalPosition = this.horizontalPosition;
    config.duration = this.setAutoHide ? this.autoHide : 0;
    return config;
  }
  onChangesAmount(value: any): void {
    this.delay(2000).then(() => {
      // tslint:disable-next-line:radix
      const amount = +this.cashbackForm.controls['amount'].value
        .replace('$', '')
        .replace(',', '');
      this.cashbackForm.valueChanges.subscribe(val => {
        if (amount > 0) {
          this.points = Math.ceil(amount / 0.01);
        } else {
          this.points = 0;
        }
      });
      const pointsText = this.format_number(this.points, '', ',', '');
      this.cashbackForm.patchValue({
        points: pointsText
      });
      this.cashbackForm.patchValue({
        amount: this.format_number(amount, '$', ',', '.')
      });
      this.amount = amount;
    });
  }
  onChangesPoints(value: any): void {
    this.delay(2000).then(() => {
      // tslint:disable-next-line:radix
      const points = +this.cashbackForm.controls['points'].value.replace(
        ',',
        ''
      );
      this.cashbackForm.valueChanges.subscribe(val => {
        if (points > 0) {
          this.amount = points / 100;
        } else {
          this.amount = 0;
        }
      });
      const amountText = this.format_number(this.amount, '$', ',', '.');
      this.cashbackForm.patchValue({
        amount: amountText
      });
      this.cashbackForm.patchValue({
        points: this.format_number(points, '', ',', '')
      });
    });
  }
  submit() {
    this.loading = true;
    const config = this._createConfig();
    const sRewardCode = 'CASHCS';
    const amt = +this.cashbackForm.controls['amount'].value
      .replace('$', '')
      .replace(',', '');
    const pts = +this.cashbackForm.controls['points'].value.replace(',', '');
    if (
      amt >= 100 &&
      pts >= 10000 &&
      (this.selectedAccount !== 'Choose' && this.selectedAccount !== undefined)   ) {
      if (pts <= this.avail_points.replace(',', '')) {
        this.loading = true;
        if (this.selectedAccount !== 'Choose' && this.selectedAccount !== undefined) {
          let keepGoing = true;
              const account_credit = {
                rewardCode: sRewardCode,
                pointsRequired: pts,
                accountId: this.selectedAccount,
                rewardCost: amt
              };
              this.redemptionService.PostCashBack(account_credit).subscribe(d => {
                 this.availablePointsService.setSessionAvailablePoints();
                  this.availablePointsService
                    .setCartAvailablePoints()
                    .then(result => {
                      this.avail_points = result.availablePoints;
                      console.log(this.avail_points);
                      this.router
                        .navigateByUrl('home', { skipLocationChange: true })
                        .then(() => this.router.navigate(['rewards/cash_rebate']));
                    });
                  this.loading = false;
                  this.snackBar.open(
                    'Cashback has been successfully added to your cart.',
                    '',
                    config
                  );
                },
                err => {
                  this.loading = false;
                  const configError = new MatSnackBarConfig();
                  configError.verticalPosition = 'top';
                  configError.duration = 5000;
                  configError.panelClass = [
                    'mat-snack-bar-container-full',
                    'mat-snack-bar-error'
                  ];
                  const key = Object.keys(err.error)[0];
                  console.log(key);
                  this.snackBar.open(
                    err.error[key][0],
                    this.action ? this.actionButtonLabel : undefined,
                    configError
                  );
                }
              );
        }
      } else {
        this.loading = false;
        const configError = new MatSnackBarConfig();
        configError.verticalPosition = 'top';
        configError.duration = 5000;
        configError.panelClass = [
          'mat-snack-bar-container-full',
          'mat-snack-bar-error'
        ];
        this.snackBar.open(
          'Points to cashback must be less than or equal to your available Points.',
          this.actionButtonLabel,
          configError
        );
      }
    } else {
      this.loading = false;
      const configError = new MatSnackBarConfig();
      configError.verticalPosition = 'top';
      configError.duration = 5000;
      configError.panelClass = [
        'mat-snack-bar-container-full',
        'mat-snack-bar-error'
      ];
      this.snackBar.open(
        'Minimum amount required is $100 and an account should be selected.',
        this.actionButtonLabel,
        configError
      );
    }
  }
  format_number(number: number, prefix, thousand_sep, decimal_sep) {
    if (number < 0) {
      prefix = '-' + prefix;
    }
    if (number !== undefined) {
      const thousand_separator = thousand_sep || ',';
      const decimal_separator = decimal_sep || '.';
      const regex = new RegExp('[^' + decimal_separator + '\\d]', 'g');
      let number_string = number
        .toString()
        .replace(regex, '')
        .toString();
      if (prefix === '$' || prefix === '-$') {
        const a = this.addZeroes(number_string);
        number_string = a
        .toString()
        .replace(regex, '')
        .toString();
      }
      const split = number_string.split(decimal_separator);
      const rest = split[0].length % 3;
      let result = split[0].substr(0, rest);
      const thousands = split[0].substr(rest).match(/\d{3}/g);
      if (thousands) {
        const separator = rest ? thousand_separator : '';
        result += separator + thousands.join(thousand_separator);
      }
      result =
        split[1] !== undefined ? result + decimal_separator + split[1] : result;
      return prefix === undefined ? result : result ? prefix + result : '';
    }
  }
  addZeroes(num) {
    const dec = num.split('.')[1];
    const len = dec && dec.length > 2 ? dec.length : 2;
    return Number(num).toFixed(len);
  }
  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  isIE() {
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf('MSIE ');
    const trident = ua.indexOf('Trident/');

    return (msie > 0 || trident > 0);
  }

}
