// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {

  // baseApiUrlProd: 'https://RewardsRedeemed.com/api/',
  // baseCorsProd: 'https://RewardsRedeemed.com/',

  // baseApiUrlTest: 'https://glacieron.bwloyalty.com/api/',
  // baseCorsTest: 'https://glacieron.bwloyalty.com/',

  // montroseUrlTest: 'https://testsaml2.bwloyalty.com/MontroseSSO.aspx?SessionID=',
  // montroseUrlProd: 'https://saml2.breakawayloyalty.com/MontroseSSO.aspx?SessionID=',

  // contentManagerAPITest: 'https://contentmanager.bwloyalty.com/api/',

   baseApiUrl: 'https://www.rewardsredeemed.com/api/',
   baseCors: 'https://www.rewardsredeemed.com/',
   montroseUrl: 'https://saml2.breakawayloyalty.com/MontroseSSO.aspx?SessionID=',
   hindmerchUrl: 'https://hinda.breakawayloyalty.com/DefaultSSO.aspx?sso=',
   contentManagerAPI: 'https://contentmanager.breakawayloyalty.com/api/',
   client_id: '202102',
   azigoEndpoint: 'https://gep.partnersmall.com/sso?hash=',
   azigoSSOKey: 'o25626604d86cd8fcf4ff37d5370f32c',
   production: true

  // baseApiUrl: 'https://glacieron.bwloyalty.com/api/',
  // baseCors: 'https://glacieron.bwloyalty.com/',
  // montroseUrl: 'https://testsaml2.bwloyalty.com/MontroseSSO.aspx?SessionID=',
  // hindmerchUrl: 'https://hinda.bwloyalty.com/DefaultSSO.aspx?sso=',
  // contentManagerAPI: 'https://contentmanager.bwloyalty.com/api/',
  // client_id: '202102',
  // azigoEndpoint: 'https://gep.partnersmall.com/sso?hash=',
  // azigoSSOKey: 'o25626604d86cd8fcf4ff37d5370f32c',
  // production: true
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
