import { PageEvent } from '@angular/material';
import { Pagination } from './../models/pagination';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PaginationService {

  paginationModel: Pagination;

constructor() {
  this.paginationModel = new Pagination();
}


  get Page(): number {
    return this.paginationModel.currentPage;
  }

  get ItemsPerPage(): number {
    return this.paginationModel.itemsPerPage;
  }

  get totalPages(): number {
    return this.paginationModel.totalPages;
  }

  change(pageEvent: PageEvent) {
    this.paginationModel.currentPage = pageEvent.pageIndex + 1;
    this.paginationModel.itemsPerPage = pageEvent.pageSize;
    this.paginationModel.totalItems = pageEvent.length;
  }
}
