import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Guid } from 'guid-typescript';

@Injectable({
  providedIn: 'root'
})
export class SsoService {

  baseApiUrl = environment.baseApiUrl;
  clientId = environment.client_id;

  constructor(private http: HttpClient) { }
getSSO(ssoGuid: Guid): Observable<any> {

  return this.http.get<any>(`${this.baseApiUrl}auth/sso/${this.clientId}/${ssoGuid.toString()}`);
}
getSSOString(guid: string): Observable<any> {

  return this.http.get<any>(`${this.baseApiUrl}auth/sso/${this.clientId}/${guid.toUpperCase()}`);
}
}
