import { CssService } from './../../../services/css.service';
import { Component, OnInit, AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import { AccountService } from 'src/app/services/account.service';
import { Options } from 'ng5-slider';
import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-reward-calculator',
  templateUrl: './reward-calculator.component.html',
  styleUrls: ['./reward-calculator.component.css']
})
export class RewardCalculatorComponent implements OnInit, AfterViewInit {
  @ViewChild('toolBarBreadcrumb', {static: false}) toolBarBreadcrumb: ElementRef;
  @ViewChild('componentEl', {static: false}) componentEl: ElementRef;
  token = localStorage.getItem('token');
  jwt = this.parseJWT(this.token);
  program_code = this.jwt['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/country'];
  avail_points: any;
  calc: any;
  value = 3500;
  options: Options = {
    floor: 1000,
    ceil: 750000,
    translate: (value: number): string => {
      return '$' + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
  };
  monthly: any;
  yearly: any;
  pending_points: any;

  constructor(private accountService: AccountService, private cssService: CssService, private router: Router) { }

  ngOnInit() {
    this.router.navigate(['home']);//BW-8141
    const rate1 = 1.00;
    if (localStorage.getItem('expire_pts') !== null && localStorage.getItem('avail_pts') !== null) {
      this.pending_points = +(localStorage.getItem('expire_pts'));
      this.avail_points = localStorage.getItem('avail_pts');
    } else {
      this.accountService.GetPendingPoints().subscribe(data => {
        this.pending_points = data;
      });
      this.accountService.GetAvailablePoints().subscribe(data => {
        this.avail_points = data.availablePoints - this.pending_points;
        this.avail_points = this.format_number(this.avail_points, '', ',', '');
      });
    }
    this.calc = (((3500 * 12) * rate1) * 0.015).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,').toString();
    this.yearly = (this.value * 12).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,').toString();
    this.monthly = (this.value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,').toString();
      // this.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  ngAfterViewInit(): void {
    this.cssService.styleToolbarIE(this.toolBarBreadcrumb);
    this.cssService.styleTextIE(this.componentEl);
    this.cssService.styleFieldsIE(this.componentEl);
    this.cssService.styleBtnIE(this.componentEl);
  }

  slider(ev: Event) {
    const rate1 = 1.00;
    this.calc = (((this.value * 12) * rate1) * 0.015).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,').toString();
    this.yearly = (this.value * 12).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,').toString();
    this.monthly = (this.value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,').toString();
  }
  parseJWT(token: any) {
    token = localStorage.getItem('token');
    const jwtData = token.split('.')[1];
    return JSON.parse(window.atob(jwtData));
  }
  format_number(number: number, prefix, thousand_sep, decimal_sep) {
    if (number < 0) {
      prefix = '-' + prefix;
    }
    const thousand_separator =  thousand_sep || ',';
    const decimal_separator = decimal_sep || '.';
    const regex		= new RegExp('[^' + decimal_separator + '\\d]', 'g');
    const number_string = number.toString().replace(regex, '').toString();
    const split = number_string.split(decimal_separator);
    const rest = split[0].length % 3;
    let result = split[0].substr(0, rest);
    const thousands = split[0].substr(rest).match(/\d{3}/g);

    if (thousands) {
      const separator = rest ? thousand_separator : '';
      result += separator + thousands.join(thousand_separator);
    }
    result = split[1] !== undefined ? result + decimal_separator + split[1] : result;
    return prefix === undefined ? result : (result ? prefix + result : '');
  }
}
