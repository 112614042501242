import { CssService } from './../../services/css.service';
import { AvailablePointsService } from 'src/app/services/availablePoints.service';
import { Component, OnInit } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material';
import { Guid } from 'guid-typescript';
import { ActivatedRoute, Router } from '@angular/router';
import { SsoService } from 'src/app/services/sso.service';
import { ContentManagerService } from 'src/app/services/content-manager.service';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { TimeOutComponent } from '../time-out/time-out.component';

@Component({
  selector: 'app-sso',
  templateUrl: './sso.component.html',
  styleUrls: ['./sso.component.css']
})
export class SsoComponent implements OnInit {

  id = '';
  ssoGuid: Guid;
  error: any;
  isError = false;
  isDemo = '';

  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;
  loading = false;
  actionButtonLabel = 'Close';
  action = true;
  constructor(private route: ActivatedRoute,
    private router: Router,
    private ssoService: SsoService,
    private snackBar: MatSnackBar,
    private availablePointsService: AvailablePointsService,
    private idle: Idle, private keepalive: Keepalive,
    private contentManagerService: ContentManagerService,
    private dialog: MatDialog,
    private cssService: CssService) {
      this.route.params.subscribe(params => {
        this.id = params['id'];
        if (params['demo'] !== null && params['demo'] !== undefined) {
          this.isDemo = params['demo'];
        } else {
          this.isDemo = 'false';
        }
      });
  }

  ngOnInit() {
    try {
    if (this.id === null) {
      // this.router.navigate(['']);
      this.loading = false;
      const config = new MatSnackBarConfig();
      config.verticalPosition = 'top';
      config.duration = 5000;
      config.panelClass = ['mat-snack-bar-container-full', 'mat-snack-bar-error'];
      this.snackBar.open('whoops!! Parameter id is null.', this.action ? this.actionButtonLabel : undefined, config);
    } else {
      this.loading = true;
        localStorage.clear();
        // this.ssoGuid = Guid.parse(this.id);


        this.ssoService.getSSOString(this.id)
          .subscribe(data => {
            localStorage.setItem('userId', data.userId);
            localStorage.setItem('token', data.token);
            localStorage.setItem('Demo',  this.isDemo);

            // this.router.navigate(['home']);

            this.availablePointsService.setCartAvailablePoints()
            .then(() => {
              this.contentManagerService.getBannerImagesPathPromise()
                .catch(() => { return; })
                .then(() => {
                  this.contentManagerService.getDocumentsPathPromise()
                    .catch(() => { return; })
                    .then(() => {
                      this.contentManagerService.getLogoImagePathToPromise()
                      .catch(() => { return; })
                      .then(() => {
                        this.contentManagerService.getCppsToPromise()
                          .catch(() =>  { return; })
                          .then(() => {
                            this.contentManagerService.getProgramNameToPromise()
                              .catch(() => { return; })
                              .then(() => {
                                this.router.navigate(['home']);
                                this.loading = false;
                              }, () => {
                                this.router.navigate(['home']);
                                this.loading = false;
                              });
                          });
                        });
                      });
                });
                this.timeout();
            }, error => {
                this.isError = true;
                this.error = error.error;
                this.loading = false;
                const config = new MatSnackBarConfig();
                config.verticalPosition = 'top';
                config.duration = 5000;
                config.panelClass = ['mat-snack-bar-container-full', 'mat-snack-bar-error'];
                const key = Object.keys(error.error)[0];
                this.snackBar.open(this.id + ', ' + error.error[key][0], this.action ? this.actionButtonLabel : undefined, config);
               });
          }, error => {
            this.isError = true;
            this.error = error.message;
            this.loading = false;
            const config = new MatSnackBarConfig();
            config.verticalPosition = 'top';
            config.duration = 5000;
            config.panelClass = ['mat-snack-bar-container-full', 'mat-snack-bar-error'];
            const key = Object.keys(error.message)[0];
            this.snackBar.open(this.id + ', ' + error.message[key][0], this.action ? this.actionButtonLabel : undefined, config);
          });
    }
  } catch {
      this.loading = false;
      const config = new MatSnackBarConfig();
      config.verticalPosition = 'top';
      config.duration = 5000;
      config.panelClass = ['mat-snack-bar-container-full', 'mat-snack-bar-error'];

      this.snackBar.open('whoops!! Something went wrong, please try again.', this.action ? this.actionButtonLabel : undefined, config);
    }

  }
  timeout() {
    // sets an idle timeout of 900 seconds, for testing purposes.
    this.idle.setIdle(900);
    // sets a timeout period of 900 seconds. after 60 seconds of inactivity, the user will be considered timed out.
    this.idle.setTimeout(60);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.idle.onIdleEnd.subscribe(() => {
      this.idleState = 'No longer idle.';
      // console.log(this.idleState);
    });
    this.idle.onTimeout.subscribe(() => {
      this.idleState = 'Timed out!';
      // console.log(this.idleState);
      this.timedOut = true;
    });
    this.idle.onIdleStart.subscribe(() => {
      this.idleState = 'You\'ve gone idle!';
      this.openDialog();
      // console.log(this.idleState);
    });
    this.idle.onTimeoutWarning.subscribe((countdown) => {
      this.idleState = 'You will time out in ' + countdown + ' seconds!';
      // console.log(this.idleState);
    });

    // sets the ping interval to 15 seconds
    this.keepalive.interval(15);

    this.keepalive.onPing.subscribe(() => {
      this.lastPing = new Date();
      // console.log('this last ping!');
    });

    this.reset();
  }

  reset() {
    this.idle.watch();
    this.idleState = 'Started.';
    // console.log('reset');
    this.timedOut = false;
  }

  openDialog() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.hasBackdrop = true;

    const dialogRef = this.dialog.open(TimeOutComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(() => {
      this.idle.onIdleEnd.subscribe(() => {
        this.idleState = 'No longer idle.';
        // console.log(this.idleState);
      });
    });
  }
}
