import { PrepareIeComponent } from './components/auth/prepare-ie/prepare-ie.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './components/auth/login/login.component';
import { HomeComponent } from './components/home/home.component';
import { RegistrationComponent } from './components/auth/registration/registration.component';
import { CashRebateComponent } from './components/rewards/cash-rebate/cash-rebate.component';
import { PointSummaryComponent } from './components/points/point-summary/point-summary.component';
import { RegistrationConfirmationComponent } from './components/auth/registration-confirmation/registration-confirmation.component';
import { ProfileComponent } from './components/account/profile/profile.component';
import { AccountLinkingComponent } from './components/account/account-linking/account-linking.component';
import { RewardCalculatorComponent } from './components/account/reward-calculator/reward-calculator.component';
import { AuthRecoveryComponent } from './components/auth/auth-recovery/auth-recovery.component';
import { GiftPointsComponent } from './components/points/gift-points/gift-points.component';
import { CatalogComponent } from './components/rewards/catalog/catalog.component';
import { WishListComponent } from './components/rewards/wish-list/wish-list.component';
import { CartComponent } from './components/rewards/cart/cart.component';
import { ShippingComponent } from './components/rewards/shipping/shipping.component';
import { GiftCardCatalogComponent } from './components/rewards/gift-card-catalog/gift-card-catalog.component';
import { EGCComponent } from './components/rewards/egc/egc.component';
import { PayWithPointsComponent } from './components/rewards/pay-with-points/pay-with-points.component';
import { ContactComponent } from './components/contact/contact.component';
import { ContactUsComponent } from './components/contactus/contactus.component';
import { CharityComponent } from './components/rewards/charity/charity.component';
import { LocalComponent } from './components/rewards/local/local.component';
import { ProductComponent } from './components/rewards/product/product.component';
import { RedemptionHistoryComponent } from './components/points/redemption-history/redemption-history.component';
import { PointHistoryComponent } from './components/points/point-history/point-history.component';
import { SsoComponent } from './components/sso/sso.component';
import { AuthResetComponent } from './components/auth/auth-reset/auth-reset.component';

export const routes: Routes = [{
  path: '',
  component: LoginComponent
},
{
  path: 'prepareie',
  component: PrepareIeComponent
},
{
  path: 'login/:demo',
  component: LoginComponent
},
{
  path: 'home',
  component: HomeComponent
},
{
  path: 'signup',
  component: RegistrationComponent
},
{
  path: 'rewards/cash_rebate',
  component: CashRebateComponent
},
{
  path: 'points/account_summary',
  component: PointSummaryComponent
},
{
  path: 'signup/confirmation',
  component: RegistrationConfirmationComponent
},
{
  path: 'account/profile',
  component: ProfileComponent
},
{
  path: 'account/link_accounts',
  component: AccountLinkingComponent
},
{
  path: 'account/rewards_calculator',
  component: RewardCalculatorComponent
},
{
  path: 'account_recovery',
  component: AuthRecoveryComponent
},
{
  path: 'points/gift_points',
  component: GiftPointsComponent
},
{
  path: 'rewards/catalog/merch',
  component: CatalogComponent
},
{
  path: 'rewards/catalog/merch/:cat',
  component: CatalogComponent
},
{
  path: 'wish_list',
  component: WishListComponent
},
{
  path: 'cart',
  component: CartComponent
},
{
  path: 'shipping',
  component: ShippingComponent
},
{
  path: 'rewards/catalog/gift_card',
  component: GiftCardCatalogComponent
},
{
  path: 'rewards/catalog/gift_card/:cat',
  component: GiftCardCatalogComponent
},
{
  path: 'rewards/pay_with_points',
  component: PayWithPointsComponent
},
{
  path: 'rewards/egc',
  component: EGCComponent
},
{
  path: 'contact',
  component: ContactComponent
},
{
  path: 'contactus',
  component: ContactUsComponent
},
{
  path: 'rewards/catalog/charity',
  component: CharityComponent
},
{
  path: 'rewards/catalog/local',
  component: LocalComponent
},
{
  path: 'rewards/product/:id',
  component: ProductComponent,
 },
//  {
//   path: 'rewards/catalog/all',
//   component: CatalogAllComponent,
//  },
//  {
//   path: 'rewards/catalog/search/:searchTerm',
//   component: SearchComponent,
//  },
 {
  path: 'points/redemption/history',
  component: RedemptionHistoryComponent,
 },
 {
  path: 'points/history',
  component: PointHistoryComponent,
 },
 {
   path: 'sso/:id',
   component: SsoComponent,
 },
 {
  path: 'sso/:id/:demo',
  component: SsoComponent,
 },
 {
   path: 'sso',
   redirectTo: '',
 },
 {
  path: 'ResetSignIn/202102/:id',
  component: AuthResetComponent,
 },
 {
  path: 'login/:error?',
  component: LoginComponent,
 }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
