import { ContentManagerService } from 'src/app/services/content-manager.service';
import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  tandc = './../../../../assets/docs/tandc.pdf';
  policy = './../../../../assets/docs/privacy_policy.pdf';
  faq = './../../../../assets/docs/faq.pdf';
  shortName = '';

  constructor(private contentManagerService: ContentManagerService) {}

  ngOnInit(): void {

    if (!(sessionStorage.getItem('privacy_policy') !== null && sessionStorage.getItem('privacy_policy') !== undefined)) {
      this.contentManagerService.getDefaultDocumentsPathPromise()
        .catch(() => { return; })
        .then(() => {
          if (sessionStorage.getItem('privacy_policy') !== null && sessionStorage.getItem('privacy_policy') !== undefined) {
            this.policy = sessionStorage.getItem('privacy_policy');
          }
      });
    } else {
      this.policy = sessionStorage.getItem('privacy_policy');
    }
    if (!(sessionStorage.getItem('tandc') !== null && sessionStorage.getItem('tandc') !== undefined)) {
      this.contentManagerService.getDefaultDocumentsPathPromise()
        .catch(() => { return; })
        .then(() => {
          if (sessionStorage.getItem('tandc') !== null && sessionStorage.getItem('tandc') !== undefined) {
            this.policy = sessionStorage.getItem('tandc');
          }
      });
    } else {
      this.policy = sessionStorage.getItem('tandc');
    }
    if (!(sessionStorage.getItem('faq') !== null && sessionStorage.getItem('faq') !== undefined)) {
      this.contentManagerService.getDefaultDocumentsPathPromise()
        .catch(() => { return; })
        .then(() => {
          if (sessionStorage.getItem('faq') !== null && sessionStorage.getItem('faq') !== undefined) {
            this.policy = sessionStorage.getItem('faq');
          }
      });
    } else {
      this.policy = sessionStorage.getItem('faq');
    }
    if (sessionStorage.getItem('program_data') !== null && sessionStorage.getItem('program_data') !== undefined) {
      this.loadProgramName();
    } else {
      this.contentManagerService.getDefaultProgramNameToPromise()
        .catch(() => { return; })
        .then(() => {
          this.loadProgramName();
      });
    }

  }

  loadProgramName() {
    const programData = JSON.parse(sessionStorage.getItem('program_data'));
    if (programData != null) {
      this.shortName = programData.shortName;
    }
  }
}
