import { environment } from './../../environments/environment';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class RedemptionService {

  baseApiUrl = environment.baseApiUrl;

  constructor(private http: HttpClient) { }

  AddtoCart(redemption: any): Observable<any> {
    return this.http.post<any>(this.baseApiUrl + `redemption/cart`, redemption);
  }
  GetCartItems(): Observable<any> {
    return this.http.get<any>(this.baseApiUrl + `redemption/cart`);
  }
  PostCashBack(account_credit: any): Observable<any> {
    return this.http.post<any>(this.baseApiUrl + `redemption/accountcredit`, account_credit);
  }
  PostShippingChoice(shipping_choice: any): Observable<any> {
    return this.http.post<any>(this.baseApiUrl + `redemption/shippingchoice`, shipping_choice);
  }
  GetTravelGuid(): Observable<any> {
    return this.http.get<any>(this.baseApiUrl + `redemption/travel`);
  }
  GetHindaGuid(): Observable<any> {
    return this.http.get<any>(this.baseApiUrl + `redemption/hinda`);
  }
  ClearCart(): Observable<any> {
    return this.http.delete<any>(this.baseApiUrl + `redemption/cart/clear`);
  }
  DeleteCartItem(id: any): Observable<any> {
    return this.http.delete<any>(this.baseApiUrl + `redemption/cart/${id}`);
  }
  DeleteShipping(): Observable<any> {
    return this.http.delete<any>(this.baseApiUrl + `redemption/cart/ship`);
  }
  RedeemItem(id: any): Observable<any> {
    return this.http.post<any>(this.baseApiUrl + `redemption/redeem/${id}`, '');
  }
  RedeemAllItems(): Observable<any> {
    return this.http.post<any>(this.baseApiUrl + `redemption/redeem`, '');
  }
}
