import { Component, OnInit, SecurityContext, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { ContentManagerService } from 'src/app/services/content-manager.service';

@Component({
  selector: 'app-footer-main',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class Footer2Component implements OnInit, AfterViewInit {
  tandc = './../../../../assets/docs/tandc.pdf';
  policy = './../../../../assets/docs/privacy_policy.pdf';
  faq = './../../../../assets/docs/faq.pdf';
  @ViewChild('footer', {static: false}) el: ElementRef;

  programShortName = '';


  constructor(private contentManagerService: ContentManagerService) {}

  ngOnInit(): void {
    if (localStorage.getItem('tandc') !== null && localStorage.getItem('tandc') !== undefined) {
      this.tandc = localStorage.getItem('tandc');
    }

    if (localStorage.getItem('faq') !== null && localStorage.getItem('faq') !== undefined) {
      this.faq = localStorage.getItem('faq');
    }

    if (localStorage.getItem('privacy_policy') !== null && localStorage.getItem('privacy_policy') !== undefined) {
      this.policy = localStorage.getItem('privacy_policy');
    }

    const programData = JSON.parse(localStorage.getItem('program_data'));
    if (programData != null) {
      this.programShortName = programData.shortName;
    }
  }


  ngAfterViewInit(): void {
    if (this.isIE()) {
      if (localStorage.getItem('cmColors') !== null && localStorage.getItem('cmColors') !== undefined) {
        const colors = JSON.parse(localStorage.getItem('cmColors'));
        this.el.nativeElement.style.color = colors.color9;
        this.el.nativeElement.style.backgroundColor = colors.color8;
      }
    }
  }


  isIE() {
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf('MSIE ');
    const trident = ua.indexOf('Trident/');

    return (msie > 0 || trident > 0);
  }
}
