import { PrepareIeComponent } from './components/auth/prepare-ie/prepare-ie.component';
import { CssService } from './services/css.service';
import { ContentManagerService } from './services/content-manager.service';
import { PaginationService } from './services/pagination.service';
import { ErrorSharingService } from './services/error-sharing.service';
import { AuthResetComponent } from './components/auth/auth-reset/auth-reset.component';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CardModule } from 'ngx-card/ngx-card';

import { MaterialModule } from './material.module';
import {DragDropModule} from '@angular/cdk/drag-drop';

import { SlideshowModule } from 'ng-simple-slideshow';
import { NgxLoadingModule, ngxLoadingAnimationTypes  } from 'ngx-loading';
import { Ng5SliderModule } from 'ng5-slider';

// this includes the core NgIdleModule but includes keepalive providers for easy wireup
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';

import { MomentModule } from 'angular2-moment'; // optional, provides moment-style pipes for date formatting

import 'hammerjs';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterComponent } from './components/layout/footer_logged_out/footer.component';
import { Footer2Component } from './components/layout/footer/footer.component';
import { HeaderComponent } from './components/layout/header_logged_out/header.component';
import { Header2Component } from './components/layout/header/header.component';
import { LoginComponent } from './components/auth/login/login.component';
import { HomeComponent } from './components/home/home.component';
import { RegistrationComponent } from './components/auth/registration/registration.component';
import { CashRebateComponent } from './components/rewards/cash-rebate/cash-rebate.component';
import { PayWithPointsComponent } from './components/rewards/pay-with-points/pay-with-points.component';
import { PointSummaryComponent } from './components/points/point-summary/point-summary.component';
import { ProfileComponent } from './components/account/profile/profile.component';
import { AcceptedTermsComponent } from './components/account/accepted-terms/accepted-terms.component';
import { AccountLinkingComponent } from './components/account/account-linking/account-linking.component';
import { RewardCalculatorComponent } from './components/account/reward-calculator/reward-calculator.component';
import { GiftPointsComponent } from './components/points/gift-points/gift-points.component';
import { AuthService } from './services/auth.service';
import { AccountService } from './services/account.service';
import { RegistrationConfirmationComponent } from './components/auth/registration-confirmation/registration-confirmation.component';
import { CacheInterceptorService } from './interceptor/cache-interceptor.service';
import { AuthInterceptorService } from './interceptor/auth.service';
import { AuthRecoveryComponent } from './components/auth/auth-recovery/auth-recovery.component';
import { PointsService } from './services/points.service';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { CatalogComponent } from './components/rewards/catalog/catalog.component';
import { RewardsService } from './services/rewards.service';
import { RedemptionService } from './services/redemption.service';
import { WishListComponent } from './components/rewards/wish-list/wish-list.component';
import { CartComponent } from './components/rewards/cart/cart.component';
import { ShippingComponent } from './components/rewards/shipping/shipping.component';
import { GiftCardCatalogComponent } from './components/rewards/gift-card-catalog/gift-card-catalog.component';
import { EGCComponent } from './components/rewards/egc/egc.component';
import { ContactComponent } from './components/contact/contact.component';
import { ContactUsComponent } from './components/contactus/contactus.component';
import { CharityComponent } from './components/rewards/charity/charity.component';
import { LocalComponent } from './components/rewards/local/local.component';
import { ProductComponent } from './components/rewards/product/product.component';
import { QuickCashRebateComponent } from './components/rewards/quick-cash-rebate/quick-cash-rebate.component';
import { RedemptionHistoryComponent } from './components/points/redemption-history/redemption-history.component';
import { PointHistoryComponent } from './components/points/point-history/point-history.component';
import { ExcelService } from './services/excel.service';
import { HindaConfirmationComponent } from './components/hinda-confirmation/hinda-confirmation.component';
import { TimeOutComponent } from './components/time-out/time-out.component';
import { CountdownModule } from 'ngx-countdown';
import { SsoService } from './services/sso.service';
import { SsoComponent } from './components/sso/sso.component';
import { ToastrModule } from 'ngx-toastr';
import { AvailablePointsService } from './services/availablePoints.service';
import { ContentLoaderModule } from '@netbasal/ngx-content-loader';
import { NgbCarouselModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    Footer2Component,
    HeaderComponent,
    Header2Component,
    LoginComponent,
    HomeComponent,
    RegistrationComponent,
    CashRebateComponent,
    PayWithPointsComponent,
    PointSummaryComponent,
    ProfileComponent,
    AcceptedTermsComponent,
    AccountLinkingComponent,
    RewardCalculatorComponent,
    GiftPointsComponent,
    RegistrationConfirmationComponent,
    AuthRecoveryComponent,
    CatalogComponent,
    WishListComponent,
    CartComponent,
    ShippingComponent,
    GiftCardCatalogComponent,
    EGCComponent,
    ContactComponent,
    ContactUsComponent,
    CharityComponent,
    LocalComponent,
    ProductComponent,
    QuickCashRebateComponent,
    // CatalogAllComponent,
    // SearchComponent,
    RedemptionHistoryComponent,
    PointHistoryComponent,
    HindaConfirmationComponent,
    TimeOutComponent,
    SsoComponent,
    AuthResetComponent,
    PrepareIeComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    SlideshowModule,
    CardModule,
    Ng5SliderModule,
    NgIdleKeepaliveModule.forRoot(),
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.circleSwish,
      fullScreenBackdrop: true,
      primaryColour: '#1c3c6d',
      secondaryColour: '#b4bd34',
    }),
    MomentModule,
    DragDropModule,
    CountdownModule,
    ToastrModule.forRoot(),
    ContentLoaderModule,
    NgbCarouselModule
  ],
  entryComponents: [
    AcceptedTermsComponent,
    QuickCashRebateComponent,
    HindaConfirmationComponent,
    TimeOutComponent
  ],
  providers: [
    {
      provide: LocationStrategy, useClass: HashLocationStrategy
    },
    CacheInterceptorService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CacheInterceptorService,
      multi: true
    },
    AuthInterceptorService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true
    },
    AuthService,
    AccountService,
    PointsService,
    RewardsService,
    RedemptionService,
    ExcelService,
    SsoService,
    ErrorSharingService,
    AvailablePointsService,
    PaginationService,
    ContentManagerService,
    CssService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
