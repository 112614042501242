import { CssService } from './../../../services/css.service';
import { Component, OnInit, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { AccountService } from 'src/app/services/account.service';
import { RewardsService } from 'src/app/services/rewards.service';
import { RedemptionService } from 'src/app/services/redemption.service';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material';
import { FormControl, FormGroup } from '@angular/forms';
import { AvailablePointsService } from 'src/app/services/availablePoints.service';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, MatSnackBarConfig } from '@angular/material';
export interface RedeemTypes {
  id: number;
  type: string;
}
@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.css']
})
export class CartComponent implements OnInit, AfterViewInit {
  @ViewChild('toolBarBreadcrumb', {static: false}) toolBarBreadcrumb: ElementRef;
  @ViewChild('componentEl', {static: false}) componentEl: ElementRef;
  [x: string]: any;
  account: any = {};
  avail_points: any;
  total_avail_points: any;
  cart = [] = [];
  message: string;
  displayedColumns: string[] = ['title', 'quantity', 'points', 'actions'];
  dataSource = new MatTableDataSource();
  ifItems: boolean;
  total: any;
  form: FormGroup;
  pending_points: any;
  loading = false;
  redeemType: RedeemTypes[] = [];
  panelEcertEmail = false;
  panelMissingEcertEmail = false;
  setAutoHide = true;
  autoHide = 3000;
  actionButtonLabel = 'Close';
  action = true;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  constructor(private accountService: AccountService, private redemptionService: RedemptionService, private router: Router,
    public snackBar: MatSnackBar, private availablePointsService: AvailablePointsService, private cssService: CssService) { }

  ngOnInit() {
    this.router.navigate(['home']);//BW-8141
    this.form = new FormGroup({
      quantity: new FormControl()
    });
    this.loading = true;
    this.redemptionService.DeleteShipping().subscribe(data => {
      this.loading = false;
      this.availablePointsService.setCartAvailablePoints()
        .then(result => {
          this.avail_points = result.availablePoints;
        });
    });
    if (localStorage.getItem('expire_pts') !== null && localStorage.getItem('avail_pts') !== null) {
      this.pending_points = +(localStorage.getItem('expire_pts'));
      this.avail_points = localStorage.getItem('avail_pts');
    } else {
      this.accountService.GetPendingPoints().subscribe(data => {
        this.pending_points = data;
      });
      this.accountService.GetAvailablePoints().subscribe(data => {
        this.avail_points = data.availablePoints - this.pending_points;
        this.avail_points = this.format_number(this.avail_points, '', ',', '');
        this.total_avail_points = data.availablePoints;
      });
    }
    this.redemptionService.GetCartItems().subscribe(data => {
      if (data != null) {

        this.form.patchValue({
          quantity: data.quantity
        });
        this.total = data.pointsRequired;
        this.dataSource = data;
        this.ifItems = true;
        for (let _i = 0; _i < data.length; _i++) {
        this.redeemType.push({ id: data[_i].id, type: data[_i].redeemType});
      }
      for (let _i = 0; _i < data.length; _i++) {
        if ( this.redeemType[_i].type === 'EC') {
          this.accountService.GetAccount().subscribe(d => {
            this.account = d;
          if (this.account.email != null && this.account.email !== undefined && this.account.email.length > 0) {
            this.panelEcertEmail = true;
          } else {
            this.panelMissingEcertEmail = true;
          }
        });
        break;
        }
      }
     } else {
        this.message = 'You have no items in your cart.';
        this.ifItems = false;
      }
    });
  }

  ngAfterViewInit(): void {
    this.cssService.styleToolbarIE(this.toolBarBreadcrumb);
    this.cssService.styleTextIE(this.componentEl);
    this.cssService.styleFieldsIE(this.componentEl);
    this.cssService.styleBtnIE(this.componentEl);
  }

  isGC(row: any) {
    const found = this.redeemType.find(x => x.id === row.id && x.type === 'GC');
    if (found) { return true; } else { return false; }
  }
  clearCart() {
    this.loading = true;
    this.redemptionService.ClearCart().subscribe(data => {
      this.loading = false;
      this.availablePointsService.setCartAvailablePoints()
        .then(result => {
          this.avail_points = result.availablePoints;
          this.router.navigateByUrl('home', { skipLocationChange: true }).then(() =>
          this.router.navigate(['cart']));
        });
    });
  }
  removeCartItem(id: any) {
    this.loading = true;
    this.redemptionService.DeleteCartItem(id).subscribe(data => {
      this.loading = false;
      this.availablePointsService.setCartAvailablePoints()
        .then(result => {
          this.avail_points = result.availablePoints;
          this.router.navigateByUrl('home', { skipLocationChange: true }).then(() =>
          this.router.navigate(['cart']));
        });
    });
  }
  viewDetails(id: any) {
    this.router.navigate([`rewards/product/${id}`]);
  }
  redeemItem(id: any) {
    const config = this._createConfig();
    if (this.panelMissingEcertEmail) {
      this.loading = false;
      const configError = new MatSnackBarConfig();
      configError.verticalPosition = 'top';
      configError.duration = 5000;
      configError.panelClass = ['mat-snack-bar-container-full', 'mat-snack-bar-error'];
      this.snackBar.open('Unfortunately we are unable to complete your e-Gift Card redemption at this time.', '', config); } else {
      const found = this.redeemType.find(x => x.id === id && x.type === 'GC');
        if (found) { this.loading = false;
          this.snackBar.open('Please use Redeem All button for Gift Cards.', '', config); } else {
            if (this.avail_points.replace(',', '') >= 0) {
            this.loading = true;
            this.redemptionService.RedeemItem(id).subscribe(data => {
            this.loading = false;
            this.router.navigateByUrl('home', { skipLocationChange: true }).then(() =>
            this.router.navigate(['cart']));
            this.loading = false;
            this.snackBar.open('An item has been successfully redeemed.', '', config);
          }, err => {
            this.loading = false;
            const configError = new MatSnackBarConfig();
            configError.verticalPosition = 'top';
            configError.duration = 5000;
            configError.panelClass = ['mat-snack-bar-container-full', 'mat-snack-bar-error'];
            const key = Object.keys(err.error)[0];
            this.snackBar.open(err.error[key][0], this.action ? this.actionButtonLabel : undefined, configError);
          });
        } else {
        this.loading = false;
        const configError = new MatSnackBarConfig();
        configError.verticalPosition = 'top';
        configError.duration = 5000;
        configError.panelClass = ['mat-snack-bar-container-full', 'mat-snack-bar-error'];
        this.snackBar.open('Points to redeem must be less than or equal to your available Points.', this.actionButtonLabel, configError);
        }
      }
    }
  }
  redeemAllItems() {
    const config = this._createConfig();
    if (this.panelMissingEcertEmail) {
      this.loading = false;
      const configError = new MatSnackBarConfig();
      configError.verticalPosition = 'top';
      configError.duration = 5000;
      configError.panelClass = ['mat-snack-bar-container-full', 'mat-snack-bar-error'];
      this.snackBar.open('Unfortunately we are unable to complete your e-Gift Card redemption at this time.', '', config); } else {
    if (this.avail_points.replace(',', '') >= 0) {
      const found = this.redeemType.find(x => x.type === 'GC');
      if (found) {
       this.loading = false;
       this.router.navigate(['shipping']);
      } else {
    this.loading = true;
    this.redemptionService.RedeemAllItems().subscribe(data => {
      this.loading = false;
      this.router.navigateByUrl('home', { skipLocationChange: true }).then(() =>
      this.router.navigate(['cart']));
      this.loading = false;
      this.snackBar.open('All items have been successfully redeemed.', '', config);
    }, err => {
      this.loading = false;
      const configError = new MatSnackBarConfig();
      configError.verticalPosition = 'top';
      configError.duration = 5000;
      configError.panelClass = ['mat-snack-bar-container-full', 'mat-snack-bar-error'];
      const key = Object.keys(err.error)[0];
      this.snackBar.open(err.error[key][0], this.action ? this.actionButtonLabel : undefined, configError);
    });
    }
      } else {
        this.loading = false;
        const configError = new MatSnackBarConfig();
        configError.verticalPosition = 'top';
        configError.duration = 5000;
        configError.panelClass = ['mat-snack-bar-container-full', 'mat-snack-bar-error'];
        this.snackBar.open('Points to redeem must be less than or equal to your available Points.', this.actionButtonLabel, configError);
      }
    }
  }
  format_number(number: number, prefix, thousand_sep, decimal_sep) {
    if (number < 0) {
      prefix = '-' + prefix;
    }
    const thousand_separator =  thousand_sep || ',';
    const decimal_separator = decimal_sep || '.';
    const regex		= new RegExp('[^' + decimal_separator + '\\d]', 'g');
    const number_string = number.toString().replace(regex, '').toString();
    const split = number_string.split(decimal_separator);
    const rest = split[0].length % 3;
    let result = split[0].substr(0, rest);
    const thousands = split[0].substr(rest).match(/\d{3}/g);

    if (thousands) {
      const separator = rest ? thousand_separator : '';
      result += separator + thousands.join(thousand_separator);
    }
    result = split[1] !== undefined ? result + decimal_separator + split[1] : result;
    return prefix === undefined ? result : (result ? prefix + result : '');
  }
  private _createConfig() {
    const config = new MatSnackBarConfig();
    config.verticalPosition = this.verticalPosition;
    config.horizontalPosition = this.horizontalPosition;
    config.duration = this.setAutoHide ? this.autoHide : 0;
    return config;
  }
}
