import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialogRef } from '@angular/material';
import { CountdownComponent } from 'ngx-countdown';
import { Idle } from '@ng-idle/core';

@Component({
  selector: 'app-time-out',
  templateUrl: './time-out.component.html',
  styleUrls: ['./time-out.component.css']
})
export class TimeOutComponent implements OnInit {

  @ViewChild('countdown', { static: false }) counter: CountdownComponent;
  token = localStorage.getItem('token');
  jwt = this.parseJWT(this.token);
  program_code = this.jwt['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/country'];
  constructor(private router: Router, private dialogRef: MatDialogRef<TimeOutComponent>,
      private idle: Idle) { }

  ngOnInit() {
  }
  onEvent($event): void {
    const timeLeft = $event.left;
    if (timeLeft === 0) {
      this.finishSession();
    }
   }

   finishSession() {
    // console.log('logout');
    this.idle.stop();
    localStorage.removeItem('token');
    localStorage.clear();
    this.dialogRef.close();
    this.router.navigate(['']);
    // location.replace(location.origin);
  }

  close() {
    this.dialogRef.close();
  }
  parseJWT(token: any) {
    token = localStorage.getItem('token');
    const jwtData = token.split('.')[1];
    return JSON.parse(window.atob(jwtData));
  }
}
