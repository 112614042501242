import { Options } from 'ng5-slider';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class ErrorSharingService {

  constructor(private toastr: ToastrService) {

   }

   showError(error: string) {
    console.log(error);
     this.toastr.show(error, 'Whoops! Something went wrong');
   }

}
