import { ContentManagerService } from './content-manager.service';
import { Injectable, Inject, ElementRef } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class CssService {

  constructor(private contentManagerService: ContentManagerService, @Inject(DOCUMENT) private doc) { }


  reloadHomePageIE() {
    const localstorageIE = localStorage.getItem('reloadIE');
    if (this.isIE()) {
      if ((localstorageIE === null || localstorageIE === undefined) || localstorageIE !== 'true') {
        localStorage.setItem('reloadIE', 'true');
        location.reload();
      }
    }
  }

  setHeaderStyle(el: ElementRef) {
    const colors = JSON.parse(localStorage.getItem('cmColors'));
    if (colors !== null && colors !== undefined && this.isIE()) {
      const anchor = el.nativeElement.getElementsByTagName('a');
        for (let i = 0; i < anchor.length; i++) {
          anchor[i].style.color = colors.color7;
        }

        const navbar = el.nativeElement.querySelectorAll('.navbar');
        for (let i = 0; i < navbar.length; i++) {
          navbar[i].style.backgroundColor = colors.color6;
          navbar[i].style.color = colors.color7;
        }

        const navLinks = el.nativeElement.querySelectorAll('.navbar-light .navbar-nav .nav-link');
        for (let i = 0; i < navLinks.length; i++) {
          navLinks[i].style.color = colors.color7;
        }

        const liHover = el.nativeElement.querySelectorAll('.navbar-default .navbar-nav > li.dropdown:hover > a, .navbar-default .navbar-nav > li.dropdown:hover > a:hover, .navbar-default .navbar-nav > li.dropdown:hover > a:focus');
        for (let i = 0; i < liHover.length; i++) {
          liHover[i].addEventListener('mouseover', function() {
            liHover[i].style.backgroundColor = colors.color6;
            liHover[i].style.color = colors.color7;
          });
        }

        const dropdownMenu = el.nativeElement.querySelectorAll('.dropdown-menu');
        for (let i = 0; i < dropdownMenu.length; i++) {
          dropdownMenu[i].style.backgroundColor = colors.color6;
          dropdownMenu[i].style.color = colors.color5;

          dropdownMenu[i].addEventListener('mouseover', function() {
            dropdownMenu[i].style.backgroundColor = colors.color6;
            dropdownMenu[i].style.color = colors.color7;
          });
        }

        const dropdownMenuLiAHover = el.nativeElement.querySelectorAll('.dropdown-menu li a:hover');
        for (let i = 0; i < dropdownMenuLiAHover.length; i++) {
          dropdownMenuLiAHover.addEventListener('mouseover', function() {
            dropdownMenuLiAHover.style.backgroundColor = colors.color6;
            dropdownMenuLiAHover.style.color = colors.color5;
          });
        }

        const matBadgeContent = el.nativeElement.querySelectorAll('.mat-badge-warn .mat-badge-content');
        for (let i = 0; i < matBadgeContent.length; i++) {
          matBadgeContent[i].style.backgroundColor = colors.color1;
        }
    }
  }

  styleToolbarIE(el: ElementRef) {
    const colors = JSON.parse(localStorage.getItem('cmColors'));
    if (colors !== null && colors !== undefined && this.isIE()) {
      const matToolbar = el.nativeElement.querySelectorAll('.mat-toolbar');
      for (let i = 0; i < matToolbar.length; i++) {
        matToolbar[i].style.backgroundColor = colors.color1;
      }

      const matToolbarPrimary = el.nativeElement.querySelectorAll('.mat-toolbar.mat-primary');
      for (let i = 0; i < matToolbarPrimary.length; i++) {
        matToolbarPrimary[i].style.backgroundColor = colors.color1;
      }

      const breadCrumb = el.nativeElement.querySelectorAll('.breadcrumb');
      for (let i = 0; i < breadCrumb.length; i++) {
        breadCrumb[i].style.backgroundColor = colors.color1;
      }
    }
  }

  styleTextIE(el: ElementRef) {
    const colors = JSON.parse(localStorage.getItem('cmColors'));
    if (colors !== null && colors !== undefined && this.isIE()) {
      const ngStarInserted = el.nativeElement.querySelectorAll('.ng-star-inserted');
      for (let i = 0; i < ngStarInserted.length; i++) {
        ngStarInserted[i].style.color = colors.color4;
      }
    }
  }

  styleBtnIE(el: ElementRef) {
    const colors = JSON.parse(localStorage.getItem('cmColors'));
    if (colors !== null && colors !== undefined && this.isIE()) {
      const matStrokedButton = el.nativeElement.querySelectorAll('.mat-stroked-button');
      for (let i = 0; i < matStrokedButton.length; i++) {
        matStrokedButton[i].style.backgroundColor = colors.color1;
      }

      const matButton = el.nativeElement.querySelectorAll('.mat-button');
      for (let i = 0; i < matButton.length; i++) {
        matButton[i].style.backgroundColor = colors.color1;
      }

      const matPrimaryMatOption = el.nativeElement.querySelectorAll('.mat-primary .mat-option.mat-selected:not(.mat-option-disabled)');
      for (let i = 0; i < matPrimaryMatOption.length; i++) {
        matPrimaryMatOption[i].style.color = colors.color1;
      }

      const matStrokeBtnMatPrimary = el.nativeElement.querySelectorAll('.mat-button.mat-primary, .mat-icon-button.mat-primary, .mat-stroked-button.mat-primary');
      for (let i = 0; i < matStrokeBtnMatPrimary.length; i++) {
        matStrokeBtnMatPrimary[i].style.color = colors.color1;
      }

      const matBtnPseudo = el.nativeElement.querySelectorAll('.mat-primary .mat-pseudo-checkbox-checked, .mat-primary .mat-pseudo-checkbox-indeterminate');
      for (let i = 0; i < matBtnPseudo.length; i++) {
        matBtnPseudo[i].style.backgroundColor = colors.color1;
      }

      const matBtnFocusOverlay = el.nativeElement.querySelectorAll('.mat-button.mat-primary .mat-button-focus-overlay, .mat-icon-button.mat-primary .mat-button-focus-overlay, .mat-stroked-button.mat-primary .mat-button-focus-overlay');
      for (let i = 0; i < matBtnFocusOverlay.length; i++) {
        matBtnFocusOverlay[i].style.backgroundColor = colors.color1;
      }

      const matFlatButton = el.nativeElement.querySelectorAll('.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary');
      for (let i = 0; i < matFlatButton.length; i++) {
        matFlatButton[i].style.backgroundColor = colors.color1;
      }
    }
  }

  styleFieldsIE(el: ElementRef) {
    const colors = JSON.parse(localStorage.getItem('cmColors'));
    if (colors !== null && colors !== undefined && this.isIE()) {
      const matformfieldoutline = el.nativeElement.querySelectorAll('.mat-form-field-outline');
      for (let i = 0; i < matformfieldoutline.length; i++) {
        matformfieldoutline[i].style.color = colors.color1;
      }

      const matRbBtn1 = el.nativeElement.querySelectorAll('.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle');
      for (let i = 0; i < matRbBtn1.length; i++) {
        matRbBtn1[i].style.color = colors.color2;
      }

      const matRbBtn2 = el.nativeElement.querySelectorAll('.mat-radio-button.mat-accent .mat-radio-inner-circle,.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple');
      for (let i = 0; i < matRbBtn2.length; i++) {
        matRbBtn2[i].style.color = colors.color2;
      }

      const matRbBtn3 = el.nativeElement.querySelectorAll('.mat-form-field.mat-focused.mat-accent .mat-select-arrow');
      for (let i = 0; i < matRbBtn3.length; i++) {
        matRbBtn3[i].style.color = colors.color2;
      }

      const matRbBtn4 = el.nativeElement.querySelectorAll('.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb');
      for (let i = 0; i < matRbBtn4.length; i++) {
        matRbBtn4[i].style.color = colors.color2;
      }
    }
  }

  styleHomeWelcomeBoxIE(el: ElementRef) {
    const colors = JSON.parse(localStorage.getItem('cmColors'));
    if (colors !== null && colors !== undefined && this.isIE()) {
      const welcomeBoxButton = el.nativeElement.querySelectorAll('.welcome_box_button');
      for (let i = 0; i < welcomeBoxButton.length; i++) {
        welcomeBoxButton[i].style.backgroundColor = colors.color1;
      }

      const welcomeBox = el.nativeElement.querySelectorAll('.welcome_box');
      for (let i = 0; i < welcomeBox.length; i++) {
        welcomeBox[i].style.backgroundColor = colors.color3;
      }

      const welcomeBoxContent = el.nativeElement.querySelectorAll('.welcome_box_content .ng-star-inserted');
      for (let i = 0; i < welcomeBoxContent.length; i++) {
        welcomeBoxContent[i].style.color = colors.color5;
      }

      const ngStarInserted = el.nativeElement.querySelectorAll('.ng-star-inserted');
      for (let i = 0; i < ngStarInserted.length; i++) {
        ngStarInserted[i].style.color = colors.color4;
      }
    }
  }

  styleHomeTiles(el: ElementRef) {
    const colors = JSON.parse(localStorage.getItem('cmColors'));
    if (colors !== null && colors !== undefined && this.isIE()) {
      const matCardFront = el.nativeElement.querySelectorAll('.front.mat-card');
      for (let i = 0; i < matCardFront.length; i++) {
        matCardFront[i].style.backgroundColor = colors.color9;

        matCardFront[i].addEventListener('mouseover', function() {
          matCardFront[i].style.backgroundColor = colors.color2;
          matCardFront[i].style.color = colors.color4;
        });

        matCardFront[i].addEventListener('mouseout', function() {
          matCardFront[i].style.backgroundColor = colors.color9;
        });
      }
    }
  }

  parseJWTForProgramCode(token: any) {
    token = localStorage.getItem('token');
    const jwtData = token.split('.')[1];
    return (JSON.parse(window.atob(jwtData)))['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/country'];
  }

  isIE() {
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf('MSIE ');
    const trident = ua.indexOf('Trident/');

    return (msie > 0 || trident > 0);
  }
}
