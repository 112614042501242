import { ContentManagerService } from 'src/app/services/content-manager.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header-logged-out',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  imgSrc = '../../../assets/images/Rewardslogo.jpg';
  programName = '';
  isLogo = false;
  showName = false;
  isDemo = false;

  constructor(private contentManagerService: ContentManagerService) {

  }

  ngOnInit(): void {
    this.contentManagerService.isDemo().subscribe(data => {
      this.isDemo = data;
    });
    this.contentManagerService.getDefaultLogoImagePathToPromise()
      .catch(() => { return; })
      .then(() => {
        if (sessionStorage.getItem('logo') !== undefined && sessionStorage.getItem('logo') !== null) {
          this.imgSrc = sessionStorage.getItem('logo');
        }

        if (sessionStorage.getItem('program_data') !== null && sessionStorage.getItem('program_data') !== undefined) {
          this.loadProgramName();
        } else {
          this.contentManagerService.getDefaultProgramNameToPromise()
            .catch(() => { return; })
            .then(() => {
              this.loadProgramName();
          });
        }
      });
  }

  loadProgramName() {
    const programData = JSON.parse(sessionStorage.getItem('program_data'));

    if (programData != null) {
      this.programName = programData.programName;
      this.isLogo = programData.isLogo;
      this.showName = programData.showName;
    }
}
}
