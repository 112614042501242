import { CssService } from './../../../services/css.service';
import { Component, OnInit, ViewChild, AfterViewInit, ChangeDetectorRef, ElementRef  } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { AccountService } from 'src/app/services/account.service';
import { PointsService } from 'src/app/services/points.service';
import { ExcelService } from 'src/app/services/excel.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-point-history',
  templateUrl: './point-history.component.html',
  styleUrls: ['./point-history.component.css']
})
export class PointHistoryComponent implements OnInit, AfterViewInit {
  @ViewChild('toolBarBreadcrumb', {static: false}) toolBarBreadcrumb: ElementRef;
  @ViewChild('componentEl', {static: false}) componentEl: ElementRef;
  avail_points: any;
  ifItems: boolean;
  displayedColumns: string[] = ['date', 'description', 'points', 'amount', 'exp_date', 'account'];
  dataSource = new MatTableDataSource();
  message: string;
  pending_points: any;
  imgXlsx = '../../../assets/images/xlsxicon.png';
  data: any[];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private accountService: AccountService, private pointsService: PointsService, private excelService: ExcelService,
     private cdr: ChangeDetectorRef, private cssService: CssService, private router: Router) { }

  ngOnInit() {
    this.router.navigate(['home']);//BW-8141
    if (localStorage.getItem('expire_pts') !== null && localStorage.getItem('avail_pts') !== null) {
      this.pending_points = +(localStorage.getItem('expire_pts'));
      this.avail_points = localStorage.getItem('avail_pts');
    } else {
      this.accountService.GetPendingPoints().subscribe(data => {
        this.pending_points = data;
      });
      this.accountService.GetAvailablePoints().subscribe(data => {
        this.avail_points = data.availablePoints - this.pending_points;
        this.avail_points = this.format_number(this.avail_points, '', ',', '');
      });
    }
    this.pointsService.GetPointHistory().subscribe(data => {
      if (data !== null) {
      this.dataSource = new MatTableDataSource(data);
      this.data = data;
      this.dataSource.paginator = this.paginator;
      setTimeout(() => this.dataSource.sort = this.sort);
      }
    });
  }

  ngAfterViewInit(): void {
    this.cssService.styleToolbarIE(this.toolBarBreadcrumb);
    this.cssService.styleTextIE(this.componentEl);
    this.cssService.styleFieldsIE(this.componentEl);
    this.cssService.styleBtnIE(this.componentEl);
  }

  exportAsXLSX(): void {
    this.excelService.exportAsExcelFile(this.data, 'Point History');
 }
 format_number(number: number, prefix, thousand_sep, decimal_sep) {
  if (number < 0) {
    prefix = '-' + prefix;
  }
  const thousand_separator =  thousand_sep || ',';
  const decimal_separator = decimal_sep || '.';
  const regex		= new RegExp('[^' + decimal_separator + '\\d]', 'g');
  const number_string = number.toString().replace(regex, '').toString();
  const split = number_string.split(decimal_separator);
  const rest = split[0].length % 3;
  let result = split[0].substr(0, rest);
  const thousands = split[0].substr(rest).match(/\d{3}/g);

  if (thousands) {
    const separator = rest ? thousand_separator : '';
    result += separator + thousands.join(thousand_separator);
  }
  result = split[1] !== undefined ? result + decimal_separator + split[1] : result;
  return prefix === undefined ? result : (result ? prefix + result : '');
}
}
