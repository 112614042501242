import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-prepare-ie',
  templateUrl: './prepare-ie.component.html',
  styleUrls: ['./prepare-ie.component.css']
})
export class PrepareIeComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {

    // setTimeout(function() {}, 1000);
    // this.router.navigate(['cart'], { skipLocationChange: true }).then(() => {
    //   this.router.navigate(['home']);
    // });
  }

}
