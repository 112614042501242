import { environment } from './../../environments/environment';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GiftPoints } from '../models/gift_points';

@Injectable()
export class PointsService {

  baseApiUrl = environment.baseApiUrl;

  constructor(private http: HttpClient) { }

  GetGiftee(balId: any): Observable<any> {
    return this.http.get<any>(this.baseApiUrl + `account/gift/giftee/${balId}`);
  }
  SubmitGiftPoints(gift_points: GiftPoints): Observable<any> {
    return this.http.post<any>(this.baseApiUrl + `account/gift`, gift_points);
  }
  GetPointSummary(): Observable<any> {
    return this.http.get<any>(this.baseApiUrl + `account/points/summary`);
  }
  GetRedemptiontSummary(): Observable<any> {
    return this.http.get<any>(this.baseApiUrl + `account/redemptions/summary`);
  }
  GetRedemptiontHistory(): Observable<any> {
    return this.http.get<any>(this.baseApiUrl + `redemption/history`);
  }
  GetTransactionsSummary(): Observable<any> {
    return this.http.get<any>(this.baseApiUrl + `account/transactions/summary`);
  }
  GetRedemptionHistory(): Observable<any> {
    return this.http.get<any>(this.baseApiUrl + `redemptions/history`);
  }
  GetPointHistory(): Observable<any> {
    return this.http.get<any>(this.baseApiUrl + `account/points/history`);
  }
}
