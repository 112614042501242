import { Component, OnInit } from '@angular/core';
import { AccountService } from 'src/app/services/account.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { LinkedAccount } from 'src/app/models/linked_account';
import { MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, MatSnackBar, MatSnackBarConfig } from '@angular/material';
import { Router } from '@angular/router';

@Component({
  selector: 'app-account-linking',
  templateUrl: './account-linking.component.html',
  styleUrls: ['./account-linking.component.css']
})
export class AccountLinkingComponent implements OnInit {
  avail_points: any;
  last_4: any;
  account: any;
  linkingForm: FormGroup;
  linked_acct: LinkedAccount;

  actionButtonLabel = 'Close';
  action = true;
  setAutoHide = true;
  autoHide = 3000;
  addExtraClass = false;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';
  errorText: string;
  pending_points: any;
  loading = false;
  isLinkingSuccess = false;
  isLinkingAvailable = true;

  constructor(private accountService: AccountService, public snackBar: MatSnackBar, private router: Router) { }

  ngOnInit() {
    this.router.navigate(['home']);//BW-8141
    if (localStorage.getItem('expire_pts') !== null && localStorage.getItem('avail_pts') !== null) {
      this.pending_points = +(localStorage.getItem('expire_pts'));
      this.avail_points = localStorage.getItem('avail_pts');
    } else {
      this.accountService.GetPendingPoints().subscribe(data => {
        this.pending_points = data;
      });
      this.accountService.GetAvailablePoints().subscribe(data => {
        this.avail_points = data.availablePoints - this.pending_points;
        this.avail_points = this.format_number(this.avail_points, '', ',', '');
      });
    }

    this.accountService.GetAccount().subscribe(data => {
      this.account = data;
      this.last_4 = this.account.accountNumber;

    });

    this.linkingForm = new FormGroup({
      acct_no: new FormControl('', [Validators.required]),
      zip_code: new FormControl('', [Validators.required, Validators.maxLength(5)])
    });

    this.accountService.verifyAvailability().subscribe(result => {
      this.isLinkingAvailable = result;
      if (!this.isLinkingAvailable) {
        this.linkingForm.disable();
      }
    }, err => {
      const configError = new MatSnackBarConfig();
      configError.verticalPosition = 'top';
      configError.duration = 5000;
      configError.panelClass = ['mat-snack-bar-container-full', 'mat-snack-bar-error'];
      const key = Object.keys(err.error)[0];
      this.snackBar.open(err.error.errors !== null && err.error.errors !== undefined ?
        err.error.errors : err.error !== null && err.error !== undefined ?
        err.error[key][0].length > 1 ? err.error[key][0]
        : err.error : 'Whoops! Something went wrong, please try again later',
      this.action ? this.actionButtonLabel : undefined, configError);
    });
  }
  private _createConfig() {
    const config = new MatSnackBarConfig();
    config.verticalPosition = this.verticalPosition;
    config.horizontalPosition = this.horizontalPosition;
    config.duration = this.setAutoHide ? this.autoHide : 0;
    // config.panelClass = this.addExtraClass ? ['snack-bar'] : undefined;
    return config;
  }
  submit() {
    this.loading = true;
    const config = this._createConfig();

    this.linked_acct = {
      accountNumber: `${this.linkingForm.value.acct_no}`,
      zipCode: `${this.linkingForm.value.zip_code}`,
    };

    this.accountService.SubmitLinkedAccount(this.linked_acct).subscribe(data => {
      this.loading = false;
      // this.snackBar.open('The account has been linked successfully.', this.action ? this.actionButtonLabel : undefined, config);
      this.isLinkingSuccess = true;
      this.linkingForm.controls['acct_no'].setValue(this.last4(this.linkingForm.controls['acct_no'].value.toString()));
      this.linkingForm.disable();
    }, err => {
      this.loading = false;
      const configError = new MatSnackBarConfig();
      configError.verticalPosition = 'top';
      configError.duration = 5000;
      configError.panelClass = ['mat-snack-bar-container-full', 'mat-snack-bar-error'];
      const key = Object.keys(err.error)[0];
      this.snackBar.open(err.error.errors !== null && err.error.errors !== undefined ?
        err.error.errors : err.error !== null && err.error !== undefined ?
        err.error[key][0].length > 1 ? err.error[key][0]
        : err.error : 'Whoops! Something went wrong',
      this.action ? this.actionButtonLabel : undefined, configError);
    });
  }
  format_number(number: number, prefix, thousand_sep, decimal_sep) {
    if (number < 0) {
      prefix = '-' + prefix;
    }
    const thousand_separator =  thousand_sep || ',';
    const decimal_separator = decimal_sep || '.';
    const regex		= new RegExp('[^' + decimal_separator + '\\d]', 'g');
    const number_string = number.toString().replace(regex, '').toString();
    const split = number_string.split(decimal_separator);
    const rest = split[0].length % 3;
    let result = split[0].substr(0, rest);
    const thousands = split[0].substr(rest).match(/\d{3}/g);

    if (thousands) {
      const separator = rest ? thousand_separator : '';
      result += separator + thousands.join(thousand_separator);
    }
    result = split[1] !== undefined ? result + decimal_separator + split[1] : result;
    return prefix === undefined ? result : (result ? prefix + result : '');
  }

  last4(value: string) {
    return `...${value.substring(value.length - 4)}`;
  }
}
