import { environment } from './../../environments/environment';
import { Observable } from 'rxjs';
import { Authorize } from './../models/authorize';
import { Register } from './../models/register';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ResetPassword } from '../models/ResetPassword';
import { AcceptedTerms } from '../models/acceptedTerms';
import { RememberMe } from '../models/rememberMe';

@Injectable()
export class AuthService {

  baseApiUrl = environment.baseApiUrl;

  constructor(private http: HttpClient) { }

  GetSecurityQuestions(): Observable<any> {
    return this.http.get<any>(this.baseApiUrl + `auth/questions`);
  }
  Login(authorize: Authorize, clientId: string): Observable<any> {
    return this.http.post<any>(this.baseApiUrl + `auth/login/${clientId}`, authorize);
  }
  Register(register: Register, clientId: string): Observable<any> {
    return this.http.post<any>(this.baseApiUrl + `auth/register/${clientId}`, register);
  }
  Recover1(body: any, clientId: string): Observable<any> {
    return this.http.post<any>(this.baseApiUrl + `auth/recover/password/${clientId}`, body);
  }
  Recover2(body: any, clientId: string): Observable<any> {
    return this.http.post<any>(this.baseApiUrl + `auth/recover/question/${clientId}`, body);
  }
  Recover3(body: ResetPassword, clientId: string): Observable<any> {
    return this.http.post<any>(this.baseApiUrl + `auth/reset/${clientId}`, body);
  }
  GetAcceptTerms(): Observable<AcceptedTerms[]> {
    return this.http.get<AcceptedTerms[]>(this.baseApiUrl + `auth/acceptTerms`);
  }
  PostAcceptTerms(accepted_terms: AcceptedTerms): Observable<any> {
    return this.http.post<any>(this.baseApiUrl + `auth/acceptTerms`, accepted_terms);
  }
  PutAcceptTerms(body: AcceptedTerms, acceptanceId: number): Observable<any> {
    return this.http.put<any>(this.baseApiUrl + `auth/acceptedTerms/${acceptanceId}`, body);
  }  
  GetRememberMe(clientId: string): Observable<RememberMe[]> {
    return this.http.get<RememberMe[]>(this.baseApiUrl + `auth/rememberMe/${clientId}`);
  }
  PostRememberMe(clientId: string, remember_me: RememberMe): Observable<any> {
    return this.http.post<any>(this.baseApiUrl + `auth/rememberMe/${clientId}`, remember_me);
  }
  PutRememberMe(body: RememberMe, clientId: string): Observable<any> {
    return this.http.put<any>(this.baseApiUrl + `auth/rememberMe/${clientId}`, body);
  }
  DeleteRememberMe(clientId: string, id: any): Observable<any> {
    return this.http.delete<any>(this.baseApiUrl + `auth/rememberMe/${clientId}/${id}`);
  }
}
